//**
// Navigation
//**

$blue: #005F90;
$lightblue: #1ca7e0;
$darkblue: #004161;
$white: #ffffff;
$normal-font: 13px;
$semi-big-font: 14px;

%highlight-background {
  background-color: $c-01;
  color: $c-08;
}
@mixin hover-background-blue {
  @include transition(all 0.5s ease-in-out);
  &:hover {
    @extend %highlight-background;
  }
}

nav {
  a {
    //display: inline-block;
    //padding: 10px 30px;
  }
}

#menu {
  padding: 0;
  nav {
    ul {
      list-style: none;
      position: relative;
      padding: 0;
      text-align: right;
      margin-bottom: 0;
      li {
        position: relative;
        display: inline-block;
        margin: 0;
        padding: 0;
        a {
          color: $c-01;
          display: block;
          text-decoration: none;
          @extend %f-a;
          padding: 8px 12px;
          border-radius: 20px;
          text-transform: uppercase;
          &.active {
            @extend %highlight-background;
            &:hover {
              color: $c-08;
            }
          }
          &:hover {
            color: $c-02;
          }
        }
        &:hover > ul {
          display: block;
        }
        ul {
          color: white;
          display: none;
          position: absolute;
          top: 100%;
          padding: 0;
          z-index: 99999;
          padding-top: 20px;
          margin: 0;
          li {
            float: none;
            width: 200px;
            a {
              @extend %f-b;
              text-transform: uppercase;
              border-radius: 0;
              background-color: rgba($c-01, 0.9);
              color: white;
              padding: 10px;
              text-overflow: ellipsis;
              word-wrap: normal;
              text-align: left;
              word-wrap: break-word;
            }
            &:hover > a {
              background-color: $c-02;
              color: $c-08;
            }
            ul {
              top: 0;
              left: 100%;
              padding: 0;
              // padding-left: 5px;
              //text-indent: 3px;
              //font-size: $normal-font;
            }
          }
        }
      }
    }
  }
  ul#social-icons {
    padding: 0;
    margin: 0;
    margin-bottom: 30px;
    text-align: right;
    li {
      display: inline-block;
      padding: 0 8px;
      a {
        img {
          max-height: 18px;
        }
      }
      #search {
        cursor: pointer;
        color: $c-01;
        font-size: 14px;
        &:hover {
          color: $c-02;
        }
      }
    }
    @media (max-width: 991px) {
      text-align: center;
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
}