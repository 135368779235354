/* Bourbon */
@import "../../../../../vendor/bower_components/bourbon/app/assets/stylesheets/_bourbon";

@import "variables";

$template: 'uno-v3';
$templateImgFolder: '../../img/#{$template}';
$fontFolder: '../../fonts/webfonts';

/*@include font-face('helvetica_ce_35_thinregular', "#{$fontFolder}/helvetica-webfont");
@include font-face('montserratregular', "#{$fontFolder}/montserrat-bold-webfont");
@include font-face('montserratbold', "#{$fontFolder}/montserrat-regular-webfont");*/

@import '../../shared';
@import 'mixins';
@import 'type';
@import 'sticky-footer';
@import 'col-height';
@import "media";
@import "buttons";
@import "tables";
@import "forms";
@import "alerts";
@import "nav";
@import "other";

.relative {
  position: relative;
}

.no-padding {
  padding: 0;
}

.left-0-padding {
  padding-left: 0;
}

.right-0-padding {
  padding-right: 0;
}

.top-0-padding {
  padding-top: 0;
}

.bottom-0-padding {
  padding-bottom: 0;
}

.dark-blue {
  color: $darkblue;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.title-lg-inline {
  display: inline-block;
  @media (max-width: $big-break) {
    display: block;
  }
}

.title-md-inline {
  display: inline-block;
  @media (max-width: $medium-break) {
    display: block;
  }
}

.center-of-unknown {
  @include centering-the-unknown;
}

#header-content {
  position: relative;
}

.top-header {
  background-color: $almostblack;
  padding: 0;
  color: $white;
  .user-pref {
    float: right;
    &#currency .currency-cont {
      margin: 0;
      padding: 0;
      li {
        display: inline-block;
        @include uno-divider($left: 1px);
        padding: 10px;
        * {
          display: inline-block;
          vertical-align: middle;
        }
        a {
          text-transform: uppercase;
          color: white;
          font-size: $tiny-font;
          text-decoration: none;
          line-height: 1;
          @include hover-blue;
        }
        /*end of a*/
        .flag {
          vertical-align: middle;
        }
      }
      /*end of li*/
    }
    /*end of currency*/
    &#search {
      padding: 0 15px;
      line-height: 36px;
      cursor: pointer;
      @include uno-divider(1px, 1px);
      @include hover-background-blue;
      &.active{
        background-color: $color_1;
      }
    }
    /*end of search*/
  }
  /*end of USER-PREF*/
}

.bottom-header {
  #logo {
    //margin: 30px 0;
    padding: 0;
    padding-bottom: 10px;
  }
  padding: 0;
  padding-top: 40px;
  padding-bottom: 20px;
  @media(max-width: 991px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

#menuBtn {
  position: absolute;
  top: 5%;
  right: 5%;
  font-size: 38px;
}

#banner {
  position: relative;
  @include transition(all 0.5s ease-in-out);
  .slick-slider {
    margin-bottom: 0 !important;
  }
  .slides {
    position: relative;
    .slide {
      background-position: top;
      background-repeat: no-repeat;
      background-size: cover;
      height: 500px;
      @media (max-width: $medium-break) {
        height: 320px;
      }
      &[href="#"] {
        cursor: default;
      }
    }
    .slick-dots {
      z-index: 9999;
      bottom: 20px;
      width: auto;
      right: 0;
      li {
        button {
          //width: 10px;
          //height: 10px;
          &:before {
            opacity: 1;
            color: $c-08;
            font-size: 12px;
            //@include transition(all 0.5s ease);
            //width: auto;
            //height: auto;
          }
        }
        //&.slick-active button:before, & button:hover:before {
        //  color: $c-04;
        //  opacity: 1;
        //  font-size: 12px;
        //}
        &.slick-active {
          button {
            &:before {
              opacity: 1;
              color: $c-04;
              font-size: 12px;
            }
            &:hover {
              &:before {
                opacity: 1;
                color: $c-04;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }
  /*end of slides*/
  .descs {
    width: 100%;
    max-width: 620px;
    position: absolute;
    background-color: transparent;
    padding: 30px;
    bottom: 20px;
    @include transition(opacity 0.5s ease-in-out);
    @media (max-width: $medium-break) {
      max-width: none;
      width: 90%;
    }
    .desc {
      .title {
        color: $c-08;
        @extend %f-c;
        line-height: 1;
        margin-bottom: 15px;
        @media(max-width: 575px) {
          font-size: 20px !important;
        }
      }
      p {
        color: $c-08;
        @extend %f-d;
        @media(max-width: 575px) {
          font-size: 25px !important;
        }
      }
      .link {
        color: $c-08;
        @extend %f-a;
        background-color: rgba(41,179,75, 0.8);
        text-transform: uppercase;
        padding: 15px 45px;
        @media(max-width: 575px) {
          padding: 15px 25px;
        }
        &:before {
          content: '\f05a';
          font-family: 'fontAwesome';
          margin-right: 5px;
        }
        &:hover {
          background-color: rgba(41,179,75, 1.0);
        }
        //@include arrow-gen(right, 3px, $darkblue, $margin: 5px);
      }
    }
    &.slick-slider {
      .slick-dots {
        bottom: 0;
        left: 0;
        li {
          button {
            width: 10px;
            height: 10px;
            &:before {
              font-size: 10px;
              color: $lightblue;
              @include transition(all 0.5s ease);
              opacity: 0.5;
              width: auto;
              height: auto;
            }
          }

          &.slick-active button:before, & button:hover:before {
            color: $darkblue;
            opacity: 1;
            font-size: 12px;
          }
        }
      }
      .slick-prev:before, .slick-next:before {
        color: $darkblue;
      }
      .slick-slide:focus{
        outline: none;
      }
    }

  }
  /*end of descs*/
}


.breadcrumb-wrapper {
  position: absolute;
  &.non-absolute {
    position: relative;
  }
  height: 40px;
  background-color: rgba(41,179,75, 0.8);
  width: 100%;
  z-index: 1;
  display: none;
  @media(min-width: 768px) {
    display: block;
  }
}

.inner-title-wrapper {
  position: absolute;
  height: 40px;
  width: 100%;
  z-index: 1;
  bottom: 10%;
}

#inner-banner {
  position: relative;
  .slide {
    width: 100%;
    height: 240px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  &.slick-slider {
    margin-bottom: 0 !important;
  }
  .overlay-fixed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 99%;
    background: rgba(0, 0, 0, 0.3);
  }
}
.overlay-simple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}

#inner-banner-empty-placeholder{
}

#breadcrumb {
  position: absolute;
  z-index: 1;
  @extend %f-b;
  text-transform: uppercase;
  display: none;
  @media(min-width: 768px) {
    display: block;
  }
  line-height: 40px;
  .text {
    color: $c-08;
  }
  .crumb {
    color: $c-08;
    &:after {
      font-family: 'fontAwesome';
      content: '\f054';
      margin: 0 5px;
      font-size: 10px;
    }
    &:last-child:after {
      content: '';
    }
  }
}

#inner-title {
  position: absolute;
  z-index: 1;
  color: $c-08;
  @extend %f-l;
}

#content {
  .top-content {
    margin-top: -60px;
  }
  .page-content {
    h1, h2, h3 {
      color: $c-01;
      padding: 15px 0;
      margin: 15px 0 0;
    }
    h2 {
      font-size: $huge-font;
    }
    p {
      color: $fontblack;
      font-size: $small-font;
      //text-align: justify;
    }
    img {
      max-width: 100%;
      //display: block;
    }
    .read-more {
      color: $darkblue;
      font-size: $normal-font;
      @include arrow-gen(right, 3px, $lightblue, $margin: 5px)
    }
    .left-content {
      @include last-child-margin;
      background-color: $white;
      padding-top: 45px;
    }
    .right-content {
      //background-color: $almostwhite;
      @include last-child-margin;
      padding-top: 45px;
    }
  }
  .bottom-content {
    margin-top: 30px;
  }
}

#news-mini {
  border-top: 15px solid rgba(white, 0.1);
  border-bottom: 15px solid rgba(black, 0.06);
  padding: 20px;
  background-color: $lightblue;
  @include transition(all 0.5s ease-in-out);
  @include centering-the-unknown;
  .title-cont {
    position: relative;
    @include uno-divider($right: 1px, $shadow: $color_2, $highlight: $color_3);
    margin-bottom: 10px;
    @media (max-width: $medium-break) {
      width: 100%;
      border: none;
      box-shadow: none;
      margin-bottom: 15px;
      padding-bottom: 10px;
      @include uno-divider($bottom: 1px, $shadow: $color_2, $highlight: $color_3);
      @include transition(all 0.5s ease-in-out);
    }
    .title {
      font-size: $bigger-font;
      color: white;
      margin-bottom: 10px;
      @media (max-width: $big-break) {
        display: inline-block;
        font-size: $normal-font;
      }
      @media (max-width: $medium-break) {
        display: inline-block;
      }

    }
    .link {
      color: $darkblue;
      display: inline-block;
      font-size: $normal-font;
      @include arrow-gen(right, 3px, white, $margin: 5px);
      @media (max-width: $medium-break) {
        float: right;
      }
    }
  }
  .news {
    @include transition(opacity 0.5s ease-in-out);
    @include uno-divider($right: 1px, $shadow: $color_2, $highlight: $color_3);
    @media (max-width: $big-break) {
      border: none;
      box-shadow: none;
    }
    .slide {
      @include centering-the-unknown;
      .date {
        @extend %f03;
        color: $darkblue;
        text-align: center;
        @media (max-width: $medium-break) {
        }
        .day {
          font-size: $huger-font;
        }
        .month {
          font-size: 14px;
        }
      }
      .desc {
        .title {
          color: $darkblue;
          margin-bottom: 5px;
          text-transform: uppercase;
          @include ellipsis(98%);
        }
        p {
          color: white;
          font-size: $normal-font;
          margin: 0;
        }
        a{
          display: block;
        }
      }
      &:focus {
        outline: none;
      }
    }
  }
  /*end of news*/
  .slide-arrow {
    @include centering-the-unknown;
    .slide-left {
      display: inline-block;
      cursor: pointer;
      font-size: 14px;
      @include hover-color;
      &:hover {
        color: white;
      }
    }
    .slide-right {
      @extend .slide-left;
      margin-left: 5px;
    }
  }
}

#newsletter {
  width: 100%;
  cursor: pointer;
  text-align: left;
  border-top: 15px solid rgba(white, 0.1);
  border-bottom: 15px solid rgba(black, 0.06);
  background-color: $blue;
  @include centering-the-unknown;
  padding: 15px;
  @include transition(all 0.5s ease-in-out);
  .col {
    @include centering-the-unknown;
    padding: 0;
    img {
      display: inline-block;
      width: auto;
    }
  }
  h3.title {
    color: $lightblue !important;
    font-size: 20px;
    margin: 0 0 5px !important;
    padding: 0;
    @media (max-width: $big-break) {
      font-size: 17px;
    }
  }
  .link {
    color: white;
    font-size: $normal-font;
    display: none;
    @include arrow-gen(right, 3px, $lightblue, $margin: 5px);
  }
  .form-group{
    display: none;
    .form-control {
      width: 100%;
      height: 34px;
      font-weight: normal;
      @extend %f02;
    }
    .form-control-feedback{
      width: auto;
      height: auto;
      margin: 10px;
    }
  }
}

.a-banner {
  margin-top: 30px;
}

#get-quote {
  margin-top: 30px;
}

#event-calendar {
  margin-top: 30px;
}

#home-video-cont {
  padding: 0;
  height: 279px;
  a {
    display: block;
    width: 100%;
  }
  .vid {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}

#home-customer-value {
  border-top: 15px solid rgba(white, 0.1);
  border-bottom: 15px solid rgba(black, 0.06);
  padding: 20px 0;
  background-color: $lightblue;
  @include transition(all 0.5s ease-in-out);
  .title {
    color: $darkblue;
  }
  img {
    margin-bottom: 15px;
  }
  p {
    color: white;
  }
}

#home-any-last-words {
  margin: 30px 0;
  color: $lightblue;
}

#popUpsContainer{
  display: none;
}

.white-popup.popup {
  padding: 10px;
}

footer {
  #footer-content {
    background-color: $almostblack;
    border-top: 5px solid $lightblue;
    padding: 0 0 40px;
    h4 {
      color: $lightblue;
      text-transform: uppercase;
      font-size: $normal-font;
      margin-bottom: 10px;
    }

    .bottom-footer {
      @include uno-divider($top: 1px, $shadow: $color_4, $highlight: $color_5);
      padding-top: 15px;
      .links {
        color: white;
      }
      .site-map {
        color: white;
      }
    }
  }
}

.top-footer {
  background-color: $almostblack;
  padding-bottom: 30px;
  h4 {
    color: $lightblue;
    text-transform: uppercase;
    font-size: $normal-font;
    margin-bottom: 10px;
  }
  & > * {
    padding-top: 20px;
    &:nth-child(2), &:nth-child(3) {
      @include uno-divider($right: 1px, $shadow: $color_4, $highlight: $color_5);
    }
    &:nth-child(2) {
      @media (max-width: $big-break) {
        box-shadow: none;
        border: 0;
      }
    }
    /*end of nth-child(2)*/
    &:first-child {
      @media (max-width: $medium-break) {
        text-align: left;
      }
    }
    /*end of first-child()*/

  }

  p {
    font-size: $tiny-font;
    color: white;
    margin: 0;
    line-height: 1.4;
  }
  .footer-logo {
    img {
      margin-bottom: 10px;
    }
  }
  .hotline {
    margin-top: 15px;
    i {
      color: $lightblue;
      vertical-align: middle;
    }
    .phone-number {
      display: inline-block;
      color: white;
      vertical-align: middle;
      margin: 0;
      >a{
        color: white;
      }
    }
  }
  .quick-nav {
    a {
      display: block;
      color: white;
      margin-bottom: 5px;
      font-size: 12px;
      &:after {
        left: 0;
      }
      &:hover {
        text-decoration: underline;
        &:after {
          left: 100%;
        }
      }
      i {
        color: $lightblue;
        padding: 0 5px;
      }
    }
  }
  .address {
    margin-bottom: 10px;
  }
}

#social-icon {
  margin-bottom: 5px;
  ul {
    @include no-listing;
    display: inline-block;
    li {
      font-size: 16px;
      display: inline-block;
      a {
        color: $almostwhite;
        padding: 0 5px;
        img{
          max-height: 18px;
          vertical-align: bottom;
        }
      }
    }
  }
}

/*inner page*/
#inner {
  &.no-banner{
    margin-top: 0;
  }
  background-color: white;
  #left-content {
  }
  #newsletter {
    margin: 0 -15px 30px;
    display: block;
    min-height: 100px;
    width: auto;
  }
}

#navigation-menu {
  position: relative;
}

#searchBar{
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $c-08;
  input{
    position: absolute;
    top: -15px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    text-align: right;
    color: $c-01;
    @extend %f-c;
    border: 0;
    box-shadow: none;
    padding: 0;
    @include placeholder {
      color: $c-01 !important;
      font-family: $proximanova-regular !important;
      font-size: 30px !important;
      @media (max-width: 991px) {
        font-size: 15px !important;
        text-align:center;
      }
    }
    @media (max-width: 991px) {
      font-size: 15px !important;
      text-align:center;
    }
  }
}
.editor-mode [data-block-editable]{
  outline: dashed $color_7 1px;
}

#divChangeViews{
  @media(min-width: 768px){
    display: none;
  }
}

[data-mh] {
  transition: height 0.3s;
}

#setia-footer-content {
  background-color: $c-06;
  .setia-top-footer {
    padding-top: 45px;
    p {
      margin-bottom: 10px;
    }
    .logo {
      margin-bottom: 20px;
    }
    .company {
      .company-left {
        @extend %f-h;
        color: $c-08;
      }
      .company-right {
        @extend %f-j;
        color: $c-08;
      }
    }
    .weekdays {
      color: $c-08;
      .weekdays-left {
        @extend %f-a;
        color: $c-08;
      }
      .weekdays-right {
        @extend %f-j;
        color: $c-08;
      }
      &:before {
        content: '\f017';
        font-family: 'fontAwesome';
        margin-right: 5px;
      }
    }
    .subscribe {
      @extend %f-j;
      color: $c-08;
      cursor: pointer;
      &:before {
        content: '\f1d8';
        font-family: 'fontAwesome';
        margin-right: 5px;
        cursor: pointer;
      }
      &:hover {
        color: $c-02;
        a {
          color: $c-02;
        }
      }
    }
    .title {
      .title-left {
        @extend %f-i;
        color: $c-08;
      }
      .title-right {
        @extend %f-e;
        color: $c-08;
      }
    }
    .address {
      @extend %f-j;
      color: $c-08;
      line-height: 20px;
    }
    .number {
      @extend %f-j;
      color: $c-08;
      .telephone {
        &:before {
          content: '\f095';
          font-family: 'fontAwesome';
          margin-right: 5px;
        }
      }
      .fax {
        &:before {
          content: '\f02f';
          font-family: 'fontAwesome';
          margin-right: 5px;
          margin-left: 10px;
        }
      }
      margin-bottom: 8px;
    }
    .email {
      @extend %f-j;
      color: $c-08;
      a {
        @extend %f-j;
        color: $c-08;
        cursor: pointer;
      }
      &:before {
        content: '\f0e0';
        font-family: 'fontAwesome';
        margin-right: 5px;
        cursor: pointer;
      }
      &:hover {
        color: $c-02;
        a {
          color: $c-02;
        }
      }
    }
    .address-block {
      @media (max-width: 991px) {
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
  }
  .setia-bottom-footer {
    padding: 30px 0;
    .copyright-left {
      @extend %f-k;
      color: $c-04;
    }
    .copyright-sitemap {
      @extend %f-k;
      color: $c-04;
      text-decoration: none;
      display: inline-block;
      a {
        @extend %f-k;
        color: $c-04;
      }
    }
    .copyright-right {
      @extend %f-k;
      color: $c-04;
    }
  }
  position: relative;
  #setia-footer-button {
    color: $c-08;
    background: $c-04;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    font-size: 17px;
    text-align: center;
    line-height: 45px;
    cursor: pointer;
    position: absolute;
    right: calc(50% - 22.5px);
    top: -22.5px;
  }
}

#geospatial-solution {
  padding: 30px 0;

  .gs-img {

  }

  .gs-text-1 {
    color: $c-01;
    @extend %f-e;
    margin-top: 50px;
  }

  .gs-text-2 {
    color: $c-01;
    @extend %f-d;
    font-size: 30px;
  }

  .gs-text-3 {
    color: $c-03;
    @extend %f-f;
    line-height: 20px;
  }

  .gs-text-4 {
    color: $c-03;
    @extend %f-f;
    line-height: 20px;
  }

  .gs-link {
    background: $c-05;
    border-radius: 20px;
    padding-right: 8px;
    text-transform: uppercase;
    a {
      color: $c-08;
      @extend %f-b;
      padding: 12px 20px;
      padding-right: 0px;
    }
    display: inline-block;
    &:after {
      color: $c-05;
      content: '\f061';
      font-family: 'fontAwesome';
      margin-left: 10px;
      background-color: $c-08;
      border-radius: 15px;
      padding: 8px 9px;
    }
    &:hover {
      background: $c-02;
      &:after {
        color: $c-02;
      }
    }
  }
}

#impact-area {
  padding-top: 230px;
  .ia-text-1 {
    color: $c-01;
    @extend %f-e;
    margin-top: 50px;
  }
  .ia-text-2 {
    color: $c-01;
    @extend %f-d;
    font-size: 30px;
  }
  .ia-text-3 {
    color: $c-03;
    @extend %f-f;
    line-height: 20px;
  }
  .ia-text-4 {
    color: $c-03;
    @extend %f-f;
    line-height: 20px;
  }

  .ia-icon-row {
    padding-top: 15px;
    // padding-bottom: 40px;
  }

  .ia-icon-1, .ia-icon-2, .ia-icon-3, .ia-icon-4 {
    padding-bottom: 10px;
  }

  .ia-icontitle-1, .ia-icontitle-2, .ia-icontitle-3, .ia-icontitle-4 {
    color: $c-01;
    @extend %f-h;
    margin: 0;
    text-transform: uppercase;
  }

  .ia-icontext-1, .ia-icontext-2, .ia-icontext-3, .ia-icontext-4 {
    color: $c-01;
    @extend %f-b;
    margin: 0;
    text-transform: uppercase;
  }

  .ia-icontext-2 {
    padding-bottom: 30px;
  }

  .ia-link {
    background: $c-05;
    border-radius: 20px;
    padding-right: 8px;
    text-transform: uppercase;
    a {
      color: $c-08;
      @extend %f-b;
      padding: 12px 20px;
      padding-right: 0;
    }
    display: inline-block;
    &:after {
      color: $c-05;
      content: '\f061';
      font-family: 'fontAwesome';
      margin-left: 10px;
      background-color: $c-08;
      border-radius: 15px;
      padding: 8px 9px;
    }
    &:hover {
      background: $c-02;
      &:after {
        color: $c-02;
      }
    }
  }

  .ia-img {
    margin-top: 50px;
  }
}

#our-projects {
  &.bg-img {
    @extend %background_img;
    background-position: bottom !important;
    background-attachment: fixed !important;
  }
  height: 280px;
  .op-first-row {
    padding-top: 45px;
    padding-bottom: 10px;
  }
  .op-title {
    color: $c-08;
    @extend %f-d;
    font-size: 30px;
  }
  .op-link {
    background: $c-04;
    border-radius: 20px;
    padding-right: 8px;
    text-transform: uppercase;
    a {
      color: $c-08;
      @extend %f-b;
      padding: 12px 20px;
      padding-right: 0;
    }
    display: inline-block;
    &:after {
      color: $c-04;
      content: '\f061';
      font-family: 'fontAwesome';
      margin-left: 10px;
      background-color: $c-08;
      border-radius: 15px;
      padding: 8px 9px;
    }
    &:hover {
      background: $c-02;
      &:after {
        color: $c-02;
      }
    }
  }
  .op-slick {
    .op-wrap {
      text-align: center;
      .op-block {
        width: 240px;
        height: 320px;
        display: inline-block;
        position: relative;
        .op-overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          overflow: hidden;
          background: linear-gradient(0deg, rgba(0, 0, 0, 1.0) 0%, rgba(0, 0, 0, 0) 50%);
          @include transition(all 0.5s ease-in-out);
          .op-name {
            color: $c-08;
            @extend %f-g;
            margin: 0;
            padding: 15px;
            position: absolute;
            bottom: 0;
            @include transition(all 0.5s ease-in-out);
            text-align: left;
          }
          .op-details {
            color: $c-08;
            @extend %f-b;
            text-transform: uppercase;
            &:before {
              content: '\f05a';
              font-family: 'fontAwesome';
              margin-right: 5px;
            }
            margin: 0;
            padding: 15px;
            position: absolute;
            bottom: -30px;
            @include transition(all 0.5s ease-in-out);
          }
        }
        &:hover {
          .op-overlay {
            background: none;
            background-color: rgba(41, 179, 75, 0.8);
            .op-name {
              bottom: 30px
            }
            .op-details {
              bottom: 7px;
            }
          }
        }
      }
    }
    .slick-prev {
      background-color: transparent;
      top: 115%;
      left: 0;
      z-index: 9999;
      @media(max-width: 991px) {
        left: calc(50% - 50px);
      }
      &:before {
        font-family: 'fontAwesome';
        content: '\f053';
        font-size: 14px;
        line-height: 1;
        color: $c-01 !important;
        opacity: 1 !important;
      }
      width: 40px;
      height: 40px;
      border: 1px solid $c-01;
      border-radius: 50%;
      text-align: center;
      padding: 9px 0;
      overflow: hidden;
      &:hover {
        background-color: $c-02;
        &:before {
          color: $c-08 !important;
        }
        border: 1px solid $c-02;
      }
    }
    .slick-next {
      background-color: transparent;
      top: 115%;
      left: 55px;
      z-index: 9999;
      @media(max-width: 991px) {
        left: calc(50% + 10px);
      }
      &:before {
        font-family: 'fontAwesome';
        content: '\f054';
        font-size: 14px;
        line-height: 1;
        color: $c-01 !important;
        opacity: 1 !important;
      }
      width: 40px;
      height: 40px;
      border: 1px solid $c-01;
      border-radius: 50%;
      text-align: center;
      padding: 9px 0;
      overflow: hidden;
      &:hover {
        background-color: $c-02;
        &:before {
          color: $c-08 !important;
        }
        border: 1px solid $c-02;
      }
    }
    .slick-prev:before, .slick-next:before {
      color: $c-02;
    }
  }
  .op-slick-arrows {
    .slick-prev {
      background-color: transparent;
      position: relative;
      display: inline-block;
      //top: 115%;
      left: 0;
      z-index: 9999;
      @media(max-width: 991px) {

      }
      &:before {
        font-family: 'fontAwesome';
        content: '\f053';
        font-size: 14px;
        line-height: 1;
        color: $c-01 !important;
        opacity: 1 !important;
      }
      width: 40px;
      height: 40px;
      border: 1px solid $c-01;
      border-radius: 50%;
      text-align: center;
      padding: 9px 0;
      overflow: hidden;
      &:hover {
        background-color: $c-02;
        &:before {
          color: $c-08 !important;
        }
        border: 1px solid $c-02;
      }
    }
    .slick-next {
      background-color: transparent;
      //top: 115%;
      //left: 55px;
      right: -15px;
      position: relative;
      display: inline-block;
      z-index: 9999;
      @media(max-width: 991px) {

      }
      &:before {
        font-family: 'fontAwesome';
        content: '\f054';
        font-size: 14px;
        line-height: 1;
        color: $c-01 !important;
        opacity: 1 !important;
      }
      width: 40px;
      height: 40px;
      border: 1px solid $c-01;
      border-radius: 50%;
      text-align: center;
      padding: 9px 0;
      overflow: hidden;
      &:hover {
        background-color: $c-02;
        &:before {
          color: $c-08 !important;
        }
        border: 1px solid $c-02;
      }
    }
    .slick-prev:before, .slick-next:before {
      color: $c-02;
    }
  }
}

#our-clients { margin-top:50px; padding-bottom: 30px;
  &.bg-img {
    @extend %background_img;
    background-position: bottom !important;
  }
  .oc-first-row {
    padding-top: 45px;
    padding-bottom: 10px;
  }
  .oc-title {
    color: $c-01;
    @extend %f-d;
    font-size: 30px;
  }
  .oc-slick {
    &.slick-slider {
      padding-left: 0 !important;
    }
    .oc-li {text-align: center;
      img { margin: 0 auto; }
      .oc-img {
        margin: 0 auto;
      }
    }
    .slick-dots {
      //width: auto;
      bottom: -30px !important;
      li {
        button {
          &:before {
            opacity: 1;
            font-size: 12px;
            color: $c-04;
          }
        }
        &.slick-active {
          button {
            &:before {
              opacity: 1;
              color: $c-05;
              font-size: 12px;
            }
            &:hover {
              &:before {
                opacity: 1;
                color: $c-05;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}

.collapse-parent-first {
  border: 1px solid $c-09;
  color: $c-03;
  @extend %f-b;
  display: block;
  padding: 15px;
  width: 210px;
  margin: 10px auto;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  // white-space: nowrap;
  font-weight: bold;
  &.in {
    border-color: $c-02;
    color: $c-02;
  }
  &:hover {
    border-color: $c-02;
    color: $c-02;
  }
}

.collapse-parent {
  color: $c-03;
  @extend %f-b;
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  line-height: 30px;
  width: 170px;
  margin: 10px auto;
  &:hover {
    color: $c-02;
  }
}

.collapse-child {
  width: 180px;
  margin: 10px auto;
  &.in {
    .collapse-parent {
      &.in {
        color: $c-02;
      }
    }
    .collapse-child {
      &.in {
        .collapse-parent {
          &.in {
            color: $c-02;
          }
        }
      }
    }
  }
}

#about-us {

  .au-img {
    padding-bottom: 30px;
  }

  .au-text-1 {
    color: $c-01 !important;
    font-family: $proximanova-regular !important;
    font-size: 24px !important;
  }

  .au-text-2 {
    color: $c-01 !important;
    font-family: $proximanova-bold !important;
    font-size: 35px !important;
  }

  .au-text-3 {
    color: $c-03 !important;
    font-family: $proximanova-regular !important;
    font-size: 14px !important;
    line-height: 20px;
  }

  .au-text-4 {
    color: $c-03 !important;
    font-family: $proximanova-regular !important;
    font-size: 14px !important;
    line-height: 20px;
  }

  .au-icon-row {
    padding: 45px 0;
    .au-icon-block {
      border: 1px solid $c-09;
      padding: 15px;
      margin-bottom: 15px;
      .au-iconimage {
        line-height: 80px;
        .au-icon-1, .au-icon-2, .au-icon-3 {

        }
      }
      .au-icontitle-1, .au-icontitle-2, .au-icontitle-3 {
        color: $c-01;
        @extend %f-d;
        margin-bottom: 0;
      }
      .au-icontext-1, .au-icontext-2, .au-icontext-3 {
        color: $c-01;
        @extend %f-f;
        margin-bottom: 0;
        text-transform: uppercase;
      }
    }
  }

  .au-text-5 {
    color: $c-01 !important;
    font-family: $proximanova-regular !important;
    font-size: 30px !important;
    position: relative;
    margin-left: 30px;
    &:before {
      content: '\f10d';
      font-family: 'fontAwesome';
      position: absolute;
      top: 6px;
      left: -30px;
      font-size: 15px;
    }
  }

  .au-text-6 {
    color: $c-03 !important;
    font-family: $proximanova-regular !important;
    font-size: 14px !important;
    line-height: 20px;
  }

  .au-text-7 {
    color: $c-01 !important;
    font-family: $proximanova-bold !important;
    font-size: 35px !important;
  }

  .au-text-8 {
    color: $c-03 !important;
    font-family: $proximanova-bold !important;
    font-size: 14px !important;
    line-height: 20px;
  }

  .au-text-9 {
    color: $c-03 !important;
    font-family: $proximanova-regular !important;
    font-size: 14px !important;
    line-height: 20px;
  }

}

#all-projects {
  padding: 45px 0;
  .op-wrap {
    display: inline-block;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    .op-block {
      width: 240px;
      height: 320px;
      display: inline-block;
      position: relative;
      .op-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background: linear-gradient(0deg, rgba(0, 0, 0, 1.0) 0%, rgba(0, 0, 0, 0) 50%);
        @include transition(all 0.5s ease-in-out);
        .op-name {
          color: $c-08;
          @extend %f-g;
          margin: 0;
          padding: 15px;
          position: absolute;
          bottom: 0;
          @include transition(all 0.5s ease-in-out);
          text-align: left;
        }
        .op-details {
          color: $c-08;
          @extend %f-b;
          text-transform: uppercase;
          &:before {
            content: '\f05a';
            font-family: 'fontAwesome';
            margin-right: 5px;
          }
          margin: 0;
          padding: 15px;
          position: absolute;
          bottom: -30px;
          @include transition(all 0.5s ease-in-out);
        }
      }
      &:hover {
        .op-overlay {
          background: none;
          background-color: rgba(41, 179, 75, 0.8);
          .op-name {
            bottom: 30px
          }
          .op-details {
            bottom: 7px;
          }
        }
      }
    }
  }
}

#product-detail {
  padding: 45px 0;
  #pd-panel {
    .panel {
      box-shadow: none;
      border-radius: 0;
      margin-top: 10px;
      .panel-heading {
        background-color: $c-08;
        border-radius: 0;
        .panel-title {
          color: $c-03;
          @extend %f-e;
          .indicator {
            border-radius: 50%;
            color: $c-08;
            font-size: 14px;
            padding: 6px 8px;
            margin: 0;
            &.fa-plus {
              background-color: $c-01;
              &:hover {
                background-color: $c-02;
              }
            }
            &.fa-minus {
              background-color: $c-02;
              &:hover {
                background-color: $c-01;
              }
            }
            cursor: pointer;
          }
        }
        &:hover {
          .panel-title {
            color: $c-02;
          }
        }
        cursor: pointer;
      }
      .panel-body {
        color: $c-03;
        @extend %f-f;
        .pd-fact-title {
          color: $c-03;
          @extend %f-o;
          margin-bottom: 10px;
        }
        .pd-fact-text {
          color: $c-03;
          @extend %f-f;
        }
      }
    }
  }
  .pd-link-1 {
    background: $c-04;
    padding: 12px 15px;
    border-radius: 20px;
    padding-right: 8px;
    text-transform: uppercase;
    color: $c-08;
    @extend %f-b;
    width: 170px;
    &:before {
      color: $c-08;
      content: '\f064';
      font-family: 'fontAwesome';
      margin-right: 10px;
    }
    display: block;
    margin: 15px 0;
    &:hover {
      background: $c-02;
    }
  }
  .pd-link-2 {
    background: $c-01;
    padding: 12px 20px;
    border-radius: 20px;
    padding-right: 8px;
    text-transform: uppercase;
    color: $c-08;
    @extend %f-b;
    width: 170px;
    &:before {
      color: $c-08;
      content: '\f075';
      font-family: 'fontAwesome';
      margin-right: 10px;
    }
    display: block;
    margin: 15px 0;
    &:hover {
      background: $c-02;
    }
  }
  #pd-name {
    color: $c-01;
    @extend %f-n;
  }
  #pd-owner-label {
    color: $c-01;
    @extend %f-f;
    display: inline-block;
    padding: 15px 0;
  }
  #pd-owner {
    color: $c-01;
    @extend %f-f;
    text-transform: uppercase;
    display: inline-block;
    padding: 15px 0;
  }
  .zoom_main {
    .zoom_main-block {
      position: relative;
      .zoom_main-btn {
        position: absolute;
        bottom: 5%;
        right: 5%;
        color: $c-08;
        background-color: $c-01;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        font-size: 16px;
        cursor: pointer;
        border-radius: 50%;
      }
    }
    @media(max-width: 991px) {
      padding: 0;
    }
  }
  .zoom_gallery {
    @media (max-width: 991px) {
      display: none;
    }
    padding-right: 120px;
    .zoom_gallery-wrap {
      padding-right: 15px;
      .zoom_gallery-block {
        cursor: pointer;
        position: relative;
        .zoom_gallery-overlay {
          //background-color: rgba(41, 179, 75, 0.8);
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          @include transition(all 0.5s ease-in-out);
          overflow: hidden;
          .fa {
            font-size: 14px;
            color: $c-08;
            position: absolute;
            left: calc(50% - 7px);
            bottom: -15px;
            @include transition(all 0.5s ease-in-out);
          }
        }
        &:hover {
          //.zoom_gallery-overlay {
          //  background-color: rgba(41, 179, 75, 0.8);
          //  .fa {
          //    bottom: calc(50% - 7px);
          //  }
          //}
        }
      }
    }
    .slick-prev {
      top: 50%;
      left: 77%;
      z-index: 9999;
      &:before {
        font-family: 'fontAwesome';
        content: '\f053';
        font-size: 14px;
        line-height: 1;
        color: $c-08 !important;
        opacity: 1 !important;
      }
      width: 40px;
      height: 40px;
      border: 1px solid $c-01;
      background-color: $c-01;
      border-radius: 50%;
      text-align: center;
      padding: 9px 0;
      overflow: hidden;
      &:hover {
        background-color: $c-01;
        &:before {
          color: $c-08 !important;
        }
        border: 1px solid $c-01;
      }
    }
    .slick-next {
      top: 50%;
      right: 2%;
      z-index: 9999;
      &:before {
        font-family: 'fontAwesome';
        content: '\f054';
        font-size: 14px;
        line-height: 1;
        color: $c-08 !important;
        opacity: 1 !important;
      }
      width: 40px;
      height: 40px;
      border: 1px solid $c-02;
      background-color: $c-02;
      border-radius: 50%;
      text-align: center;
      padding: 9px 0;
      overflow: hidden;
      &:hover {
        background-color: $c-02;
        &:before {
          color: $c-08 !important;
        }
        border: 1px solid $c-02;
      }
    }
    .slick-prev:before, .slick-next:before {
      color: $c-02;
    }
  }
}

.news {
  @media(max-width: 991px) {
    p {
      display: none;
    }
  }
}

.timeline-heading h4 {
  margin:0;
}

.timeline {list-style: none; padding: 20px 0 20px; position: relative;
  &:before { top: 0; bottom: 0; position: absolute; content: " "; width: 3px; background-color: #eeeeee; left: 50%; margin-left: -1.5px; }
  > li{ margin-bottom: 20px; position: relative;
    &:before { content:''; display: table; }
    &:after { content:''; display: table; clear:both; }
    > .timeline-panel {  width: 46%; float: left; border: 1px solid #d4d4d4; border-radius: 2px; padding: 25px; position: relative; -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175); box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
      &:before { position: absolute; top: 26px; right: -15px; display: inline-block; border-top: 15px solid transparent; border-left: 15px solid #ccc; border-right: 0 solid #ccc; border-bottom: 15px solid transparent;  content: " "; }
      &:after { position: absolute; top: 27px; right: -14px; display: inline-block; border-top: 14px solid transparent; border-left: 14px solid #fff; border-right: 0 solid #fff; border-bottom: 14px solid transparent; content: " "; }
    }
    > .timeline-badge { color: #fff; width: 50px; height: 50px; line-height: 50px;  font-size: 1.4em; text-align: center;  position: absolute;  top: 16px; left: 50%;  margin-left: -25px;  background-color: rgb(0, 20, 137);  z-index: 100;  border-top-right-radius: 50%;  border-top-left-radius: 50%; border-bottom-right-radius: 50%;  border-bottom-left-radius: 50%;
      p { color:#fff !important; }
      &.primary { background-color: #2e6da4 !important; }
      &.warning { background-color: #3f903f !important; }
      &.danger { background-color: #d9534f !important;}
      &.info { background-color: #5bc0de !important;}
    }
    &.timeline-inverted{
      > .timeline-panel { float: right;
        &:before { border-left-width: 0; border-right-width: 15px;  left: -15px;  right: auto; }
        &:after {  border-left-width: 0;  border-right-width: 14px;  left: -14px;  right: auto; }
      }
    }
  }
}
.timeline-title { margin-top: 0; color: inherit; }
.timeline-body > p,
.timeline-body > ul { margin-bottom: 0; }
.timeline-body > p + p { margin-top: 5px; }


.side-menu { 
  ul{
    list-style:none;
    margin:0;
    padding:0;
    li{ position: relative; margin:10px 0; text-transform:uppercase; @extend %f-b;
      a { padding:15px; border: 1px solid $c-09; display: block; text-transform:uppercase; @extend %f-b; padding-right: 30px; color:$c-03; font-weight: bold;
        &:hover { border-color:#29b34b; color:#29b34b; }
        &.arrow { position: absolute; right: 0; top: 0; border: 0; margin: 0; padding-right:15px;
          &:before { content: ''; }
        }

      }
    }
  }
}