/* columns of same height styles */

.row-full-height {
  height: 100%;
}
.col-full-height {
  height: 100%;
  vertical-align: middle;
}
.row-same-height {
  display: table;
  table-layout: fixed;
}
/*revert back to normal in md*/
.row-md-same-height {
  display: table;
  table-layout: fixed;
  @media (max-width: 992px) {
    display: block;
  }
}
.row-sm-same-height {
  display: table;
  table-layout: fixed;
  @media (max-width: 768px) {
    display: block;
  }
}
.col-xs-height {
  display: table-cell;
  float: none !important;
}
/*alignments*/
.col-top {
  vertical-align: top;
}
.col-middle {
  vertical-align: middle;
}
.col-bottom {
  vertical-align: bottom;
}
//@include responsive($big-break) {  }
@media (min-width: 768px) {
  .col-sm-height {
    display: table-cell;
    float: none !important;
  }
}
@media (min-width: 992px) {
  .col-md-height {
    display: table-cell;
    float: none !important;
  }
}
@media (min-width: 1000px) {
  .col-lg-height {
    display: table-cell;
    float: none !important;
  }
}

%background_img {
  background: {
    size: cover !important;
    position: center !important;
    repeat: no-repeat !important;
  }
}

@media (max-width: 9999px) {
  .m-lg-left {
    text-align: left;
  }
  .m-lg-center {
    text-align: center;
  }
  .m-lg-right {
    text-align: right;
  }
}

@media (max-width: 1199px) {
  .m-md-left {
    text-align: left;
  }
  .m-md-center {
    text-align: center;
  }
  .m-md-right {
    text-align: right;
  }
}

@media (max-width: 991px) {
  .m-sm-left {
    text-align: left;
  }
  .m-sm-center {
    text-align: center;
  }
  .m-sm-right {
    text-align: right;
  }
}

@media (max-width: 767px) {
  .m-xs-left {
    text-align: left;
  }
  .m-xs-center {
    text-align: center;
  }
  .m-xs-right {
    text-align: right;
  }
}

@media (max-width: 575px) {
  .m-xx-left {
    text-align: left;
  }
  .m-xx-center {
    text-align: center;
  }
  .m-xx-right {
    text-align: right;
  }
}
