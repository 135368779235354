//Only variables should be place here. No direct styles please.


//Override Bootstrap Variables Here
$icon-font-path: "../../fonts/bootstrap/";

//Font-Awesome Variables
$fa-font-path: "../../fonts/font-awesome";

//Ionicons Variables
$ionicons-font-path: "../../fonts/ionicons";

//Flag-icon-css Variables
$flag-icon-css-path: '../../img/flag-icon';

$fontFolder: '../../fonts/webfonts';
$proximanova-regular: 'ProximaNova-Regular';
$proximanova-semibold: 'ProximaNova-Semibold';
$proximanova-bold: 'ProximaNova-Bold';

@font-face {
  font-family: $proximanova-regular;
  src: url('#{$fontFolder}/#{$proximanova-regular}.eot?#iefix') format('embedded-opentype'),
  url('#{$fontFolder}/#{$proximanova-regular}.otf') format('otf'),
  url('#{$fontFolder}/#{$proximanova-regular}.woff') format('woff'),
  url('#{$fontFolder}/#{$proximanova-regular}.ttf') format('truetype');
}

@font-face {
  font-family: $proximanova-semibold;
  src: url('#{$fontFolder}/#{$proximanova-semibold}.eot?#iefix') format('embedded-opentype'),
  url('#{$fontFolder}/#{$proximanova-semibold}.otf') format('otf'),
  url('#{$fontFolder}/#{$proximanova-semibold}.woff') format('woff'),
  url('#{$fontFolder}/#{$proximanova-semibold}.ttf') format('truetype');
}

@font-face {
  font-family: $proximanova-bold;
  src: url('#{$fontFolder}/#{$proximanova-bold}.eot?#iefix') format('embedded-opentype'),
  url('#{$fontFolder}/#{$proximanova-bold}.otf') format('otf'),
  url('#{$fontFolder}/#{$proximanova-bold}.woff') format('woff'),
  url('#{$fontFolder}/#{$proximanova-bold}.ttf') format('truetype');
}

//**
// Project Settings
//**

// Consistent settings
$br: 5px;
// Border-radius
$btn-br: 5px;
// Button border-radius
$padding: 40px;
// Padding and margins
$line-height: 44px;
// Consistent height for buttons, inputs etc.

// Responsive breakpoints
$small-break: 480px;
$medium-break: 768px;
$big-break: 991px;

// Better default colors
// Cool
$aqua: #7fdbff;
$blue: #0074d9;
$navy: #001f3f;
$teal: #39cccc;
$green: #2ecc40;
$olive: #3d9970;
$lime: #01ff70;
// Warm
$yellow: #ffdc00;
$orange: #ff851b;
$red: #ff4136;
$fuchsia: #f012be;
$purple: #b10dc9;
$maroon: #85144b;
// Gray Scale
$white: #fff;
$silver: #ddd;
$grey: #aaa;
$black: #111;

// Project color variables
$primary-color: #1271db;
$secondary-color: #233e5b;
$tertiary-color: #aaa;

$danger-color: $red;
// Bad color for errors, validation etc.
$success-color: $green;
// Good color for success etc.
$info-color: $blue;
// Good color for success etc.
$warning-color: $orange;
// Good color for success etc.
$subtle-color: $grey;
// Subtle color for subtle text


$almostblack: #2C2C2C;
$fontblack: #000000;
$lightblue: #1ca7e0;
$blue: #005F90;
$darkblue: #004161;
$almostwhite: #F3F3F3;
$white: #ffffff;
$minor-white: rgba(#ffffff, 0.2);
$semi-white: rgba(#ffffff, 0.5);
$not-so-semi-white: rgba(#ffffff, 0.8);

//sathiya
$color_1: #369FDC;
$color_2: #0083B4;
$color_3: #16BCF9;
$color_4: #151515;
$color_5: #424242;
$color_6: #333;
$color_7: #7f7f7f;

$c-01: #006eb9;
$c-02: #29b34b;
$c-03: #000000;
$c-04: #3c9fd8;
$c-05: #0066b3;
$c-06: #004890;
$c-07: #f9f9f9;
$c-08: #ffffff;
$c-09: #ebebeb;

$tiny-font: 10px;
$small-font: 12px;
$normal-font: 13px;
$semi-big-font: 14px;
$big-font: 16px;
$bigger-font: 18px;
$huge-font: 24px;
$huger-font: 30px;

%highlight-color {
  color: $lightblue;
}

%highlight-background {
  background-color: $lightblue;
  color: $white;
}