/* Bourbon */
@font-face {
  font-family: "ProximaNova-Regular";
  src: url("../../fonts/webfonts/ProximaNova-Regular.eot?#iefix") format("embedded-opentype"), url("../../fonts/webfonts/ProximaNova-Regular.otf") format("otf"), url("../../fonts/webfonts/ProximaNova-Regular.woff") format("woff"), url("../../fonts/webfonts/ProximaNova-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "ProximaNova-Semibold";
  src: url("../../fonts/webfonts/ProximaNova-Semibold.eot?#iefix") format("embedded-opentype"), url("../../fonts/webfonts/ProximaNova-Semibold.otf") format("otf"), url("../../fonts/webfonts/ProximaNova-Semibold.woff") format("woff"), url("../../fonts/webfonts/ProximaNova-Semibold.ttf") format("truetype");
}

@font-face {
  font-family: "ProximaNova-Bold";
  src: url("../../fonts/webfonts/ProximaNova-Bold.eot?#iefix") format("embedded-opentype"), url("../../fonts/webfonts/ProximaNova-Bold.otf") format("otf"), url("../../fonts/webfonts/ProximaNova-Bold.woff") format("woff"), url("../../fonts/webfonts/ProximaNova-Bold.ttf") format("truetype");
}

.top-header .user-pref#currency .currency-cont li a:hover {
  color: #1ca7e0;
}

#menu nav ul li a.active, .top-header .user-pref#search:hover {
  background-color: #1ca7e0;
  color: #ffffff;
}

/*@include font-face('helvetica_ce_35_thinregular', "../../fonts/webfonts/helvetica-webfont");
@include font-face('montserratregular', "../../fonts/webfonts/montserrat-bold-webfont");
@include font-face('montserratbold', "../../fonts/webfonts/montserrat-regular-webfont");*/
/* Bourbon */
.clearfix,
.group {
  zoom: 1;
}

.clearfix:before, .clearfix:after,
.group:before,
.group:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden;
}

.clearfix:after,
.group:after {
  clear: both;
}

.buffer-xs-0 {
  margin-top: 0;
}

.buffer-xs-10 {
  margin-top: 10px;
}

.buffer-xs-20 {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .buffer-sm-0 {
    margin-top: 0;
  }
  .buffer-sm-10 {
    margin-top: 10px;
  }
}

@media (min-width: 992px) {
  .buffer-md-0 {
    margin-top: 0;
  }
  .buffer-md-10 {
    margin-top: 10px;
  }
}

.icheckbox_square-blue,
.iradio_square-blue {
  background-image: url("../../img/iCheck/square/blue.png");
  /* HiDPI support */
}

@media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
  .icheckbox_square-blue,
  .iradio_square-blue {
    background-image: url("../../img/iCheck/square/blue@2x.png");
  }
}

.bannerMfp .mfp-iframe {
  background: none;
  box-shadow: none;
}

.mfp-bg {
  z-index: 999998;
}

.mfp-wrap {
  z-index: 999999;
}

.uno-mfp-popup {
  max-width: 600px;
  margin: 10px auto;
  color: #000;
  font-family: helvetica, arial, sans-serif;
  position: relative;
}

.uno-mfp-popup.white {
  background-color: white;
}

.uno-mfp-popup.white .mfp-close {
  color: black;
  font-size: 22px;
}

.uno-mfp-popup.white > .box {
  border-radius: 0;
}

.uno-mfp-popup > .popup-container {
  padding: 20px;
}

.uno-mfp-popup > .popup-container > h4 {
  margin: 0;
}

.mfp-iframe-holder .mfp-content {
  max-width: 1200px;
}

/* overlay at start */
.mfp-fade.mfp-bg {
  opacity: 0;
  transition: all 0.15s ease-out;
}

/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}

/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.15s ease-out;
}

/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

.mfp-close-btn-in .mfp-close {
  color: #FFF;
}

.select2-dropdown, .select2-container--bootstrap .select2-search--dropdown .select2-search__field, .select2-container--bootstrap .select2-selection {
  border-radius: 0;
}

.slick-loading .slick-list {
  background-image: url("../../img/slick/ajax-loader.gif");
}

@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url("../../fonts/slick/slick.eot");
  src: url("../../fonts/slick/slick.eot?#iefix") format("embedded-opentype"), url("../../fonts/slick/slick.woff") format("woff"), url("../../fonts/slick/slick.ttf") format("truetype"), url("../../fonts/slick/slick.svg#slick") format("svg");
}

.slick-arrow:before {
  transition: opacity 0.5s;
}

/* TinyMCE Fullscreen Z-Index Override */
div.mce-fullscreen {
  z-index: 9999;
}

label.mce-label {
  max-width: none;
}

/* TINYMCE CUSTOMISATION */
.mce-window {
  max-width: 90% !important;
}

.mce-panel {
  max-width: 100% !important;
}

.mce-tabs {
  max-width: 100% !important;
}

.mce-container-body {
  max-width: 100% !important;
}

.mce-container {
  max-width: 100% !important;
}

.mejs-overlay-button {
  background-image: url("../../assets/mediaelement/bigplay.svg");
}

.no-svg .mejs-overlay-button {
  background-image: url("../../assets/mediaelement/bigplay.png");
}

.mejs-overlay-loading {
  background-image: url("../../assets/mediaelement/background.png");
}

.mejs-overlay-loading span {
  background-image: url("../../assets/mediaelement/loading.gif");
}

.mejs-container .mejs-controls,
.mejs-controls .mejs-volume-button .mejs-volume-slider,
.mejs-controls .mejs-captions-button .mejs-captions-selector,
.mejs-captions-text,
.mejs-controls .mejs-sourcechooser-button .mejs-sourcechooser-selector,
.mejs-postroll-layer,
.mejs-postroll-close,
.mejs-controls .mejs-speed-button .mejs-speed-selector {
  background-image: url("../../assets/mediaelement/background.png");
}

.mejs-controls .mejs-button button {
  background-image: url("../../assets/mediaelement/controls.svg");
}

.no-svg .mejs-controls .mejs-button button {
  background-image: url("../../assets/mediaelement/controls.png");
}

.mejs-controls .mejs-button.mejs-skip-back-button {
  background-image: url("../../assets/mediaelement/skipback.png");
}

.jstree-default .jstree-icon,
.jstree-default .jstree-file,
.jstree-default .jstree-folder {
  background-image: url("../../img/jstree/32px.png");
}

.jstree-default > .jstree-container-ul .jstree-loading > .jstree-ocl {
  background-image: url("../../img/jstree/throbber.gif");
}

#jstree-dnd.jstree-default .jstree-ok,
#jstree-dnd.jstree-default .jstree-er,
#jstree-dnd.jstree-default-small .jstree-ok,
#jstree-dnd.jstree-default-small .jstree-er,
.jstree-default-large .jstree-node,
.jstree-default-large .jstree-icon,
#jstree-dnd.jstree-default-large .jstree-ok,
#jstree-dnd.jstree-default-large .jstree-er {
  background-image: url("../../img/jstree/32px.png");
}

.jstree-default-small .jstree-icon,
.jstree-default-small .jstree-file,
.jstree-default-small .jstree-folder {
  background-image: url("../../img/jstree/32px.png");
}

.jstree-default-large .jstree-icon,
.jstree-default-large .jstree-file,
.jstree-default-large .jstree-folder {
  background-image: url("../../img/jstree/32px.png");
}

#jstree-dnd.jstree-dnd-responsive > .jstree-ok,
#jstree-dnd.jstree-dnd-responsive > .jstree-er {
  background-image: url("../../img/jstree/40px.png");
}

.jstree-default-responsive .jstree-node,
.jstree-default-responsive .jstree-icon,
.jstree-default-responsive .jstree-node > .jstree-ocl,
.jstree-default-responsive .jstree-themeicon,
.jstree-default-responsive .jstree-checkbox,
.jstree-default-responsive .jstree-file,
.jstree-default-responsive .jstree-folder {
  background-image: url("../../img/jstree/40px.png");
}

/* No bold */
.no-bold {
  font-weight: normal;
}

/* IE Image Fix */
img {
  -ms-interpolation-mode: bicubic;
}

/* Easy background image */
.bg-img {
  background: no-repeat center;
}

.bg-img.bg-cover {
  background-size: cover;
}

.bg-img.bg-contain {
  background-size: contain;
}

/* Button Block Text Overflow */
.btn-block {
  text-overflow: ellipsis;
  overflow: hidden;
}

/* Hide CSRF Token */
#_token {
  display: none;
}

/* Bootstrap: Remove gutter */
.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

/* Remove Transition */
.no-transition {
  transition: none !important;
}

a.block {
  display: block;
}

.btn:focus {
  outline: none;
}

body {
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
}

#newsletter .form-group .form-control {
  font-size: 14px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

#news-mini .news .slide .date {
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}

#menu nav ul li a, #banner .descs .desc .link, #setia-footer-content .setia-top-footer .weekdays .weekdays-left {
  font-family: "ProximaNova-Bold";
  font-size: 14px;
}

#menu nav ul li ul li a, #breadcrumb, #geospatial-solution .gs-link a, #impact-area .ia-icontext-1, #impact-area .ia-icontext-2, #impact-area .ia-icontext-3, #impact-area .ia-icontext-4, #impact-area .ia-link a, #our-projects .op-link a, #our-projects .op-slick .op-wrap .op-block .op-overlay .op-details, .collapse-parent-first, .collapse-parent, #all-projects .op-wrap .op-block .op-overlay .op-details, #product-detail .pd-link-1, #product-detail .pd-link-2, .side-menu ul li, .side-menu ul li a {
  font-family: "ProximaNova-Regular";
  font-size: 12px;
}

#banner .descs .desc .title, #searchBar input {
  font-family: "ProximaNova-Regular";
  font-size: 30px;
}

#banner .descs .desc p, #geospatial-solution .gs-text-2, #impact-area .ia-text-2, #our-projects .op-title, #our-clients .oc-title, #about-us .au-icon-row .au-icon-block .au-icontitle-1, #about-us .au-icon-row .au-icon-block .au-icontitle-2, #about-us .au-icon-row .au-icon-block .au-icontitle-3 {
  font-family: "ProximaNova-Bold";
  font-size: 37px;
}

#setia-footer-content .setia-top-footer .title .title-right, #geospatial-solution .gs-text-1, #impact-area .ia-text-1, #product-detail #pd-panel .panel .panel-heading .panel-title {
  font-family: "ProximaNova-Regular";
  font-size: 24px;
}

#geospatial-solution .gs-text-3, #geospatial-solution .gs-text-4, #impact-area .ia-text-3, #impact-area .ia-text-4, #about-us .au-icon-row .au-icon-block .au-icontext-1, #about-us .au-icon-row .au-icon-block .au-icontext-2, #about-us .au-icon-row .au-icon-block .au-icontext-3, #product-detail #pd-panel .panel .panel-body, #product-detail #pd-panel .panel .panel-body .pd-fact-text, #product-detail #pd-owner-label, #product-detail #pd-owner {
  font-family: "ProximaNova-Regular";
  font-size: 14px;
}

#our-projects .op-slick .op-wrap .op-block .op-overlay .op-name, #all-projects .op-wrap .op-block .op-overlay .op-name {
  font-family: "ProximaNova-Bold";
  font-size: 20px;
}

#setia-footer-content .setia-top-footer .company .company-left, #impact-area .ia-icontitle-1, #impact-area .ia-icontitle-2, #impact-area .ia-icontitle-3, #impact-area .ia-icontitle-4 {
  font-family: "ProximaNova-Bold";
  font-size: 12px;
}

#setia-footer-content .setia-top-footer .title .title-left {
  font-family: "ProximaNova-Bold";
  font-size: 24px;
}

#setia-footer-content .setia-top-footer .company .company-right, #setia-footer-content .setia-top-footer .weekdays .weekdays-right, #setia-footer-content .setia-top-footer .subscribe, #setia-footer-content .setia-top-footer .address, #setia-footer-content .setia-top-footer .number, #setia-footer-content .setia-top-footer .email, #setia-footer-content .setia-top-footer .email a {
  font-family: "ProximaNova-Regular";
  font-size: 13px;
}

#setia-footer-content .setia-bottom-footer .copyright-left, #setia-footer-content .setia-bottom-footer .copyright-sitemap, #setia-footer-content .setia-bottom-footer .copyright-sitemap a, #setia-footer-content .setia-bottom-footer .copyright-right {
  font-family: "ProximaNova-Regular";
  font-size: 10px;
}

#inner-title {
  font-family: "ProximaNova-Bold";
  font-size: 35px;
}

#product-detail #pd-name {
  font-family: "ProximaNova-Bold";
  font-size: 30px;
}

#product-detail #pd-panel .panel .panel-body .pd-fact-title {
  font-family: "ProximaNova-Bold";
  font-size: 16px;
}

body {
  line-height: 1.2;
}

blockquote {
  border-left: 2px solid #1271db;
  font-family: Georgia, Times, "Times New Roman", serif;
  font-size: 1.4em;
  font-style: italic;
  margin: 0 0 20px;
  padding-left: 25px;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Open Sans", sans-serif;
  line-height: 1;
  font-weight: bold;
}

h1 {
  font-size: 42px;
  font-style: normal;
  margin: 0 0 25px;
}

h2 {
  font-size: 30px;
  font-style: normal;
  margin: 0 0 25px;
}

h3 {
  font-size: 24px;
  font-style: normal;
  margin: 0 0 25px;
}

h4 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin: 0 0 25px;
}

h5 {
  font-size: 16px;
  font-style: normal;
  margin: 0 0 25px;
}

p {
  margin: 0 0 20px;
  font-size: 12px;
}

pre {
  background: #222;
  color: #fff;
  display: block;
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
  font-size: 13px;
  line-height: 20px;
  margin: 0 0 20px;
  padding: 10px;
  word-break: break-all;
  word-wrap: break-word;
}

code {
  border-radius: 5px;
  background: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
  font-size: 13px;
  line-height: 20px;
  padding: 2px 10px;
  word-break: break-all;
  word-wrap: break-word;
}

a {
  color: #1271db;
  display: inline-block;
}

a:hover, a:focus {
  text-decoration: none;
}

/*

// Responsive styles if needed
// @include responsive($big-break) {
// }
@include responsive($small-break) {
  body {
    font-size: 14px;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 10px;
  }

  h1 {
    font-size: 26px;
  }

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 16px;
  }

  h4,
  h5,
  p,
  ul,
  ol {
    font-size: 14px;
  }
}
*/
html, body, #wrapper {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

#wrapper {
  display: table;
  width: 100%;
  table-layout: fixed;
}

main {
  display: table-row;
}

header {
  display: table-row;
  height: 1px;
}

footer {
  display: table-row;
  height: 1px;
}

.mm-page {
  height: 100%;
}

/* columns of same height styles */
.row-full-height {
  height: 100%;
}

.col-full-height {
  height: 100%;
  vertical-align: middle;
}

.row-same-height {
  display: table;
  table-layout: fixed;
}

/*revert back to normal in md*/
.row-md-same-height {
  display: table;
  table-layout: fixed;
}

@media (max-width: 992px) {
  .row-md-same-height {
    display: block;
  }
}

.row-sm-same-height {
  display: table;
  table-layout: fixed;
}

@media (max-width: 768px) {
  .row-sm-same-height {
    display: block;
  }
}

.col-xs-height {
  display: table-cell;
  float: none !important;
}

/*alignments*/
.col-top {
  vertical-align: top;
}

.col-middle {
  vertical-align: middle;
}

.col-bottom {
  vertical-align: bottom;
}

@media (min-width: 768px) {
  .col-sm-height {
    display: table-cell;
    float: none !important;
  }
}

@media (min-width: 992px) {
  .col-md-height {
    display: table-cell;
    float: none !important;
  }
}

@media (min-width: 1000px) {
  .col-lg-height {
    display: table-cell;
    float: none !important;
  }
}

#our-projects.bg-img, #our-clients.bg-img {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

@media (max-width: 9999px) {
  .m-lg-left {
    text-align: left;
  }
  .m-lg-center {
    text-align: center;
  }
  .m-lg-right {
    text-align: right;
  }
}

@media (max-width: 1199px) {
  .m-md-left {
    text-align: left;
  }
  .m-md-center {
    text-align: center;
  }
  .m-md-right {
    text-align: right;
  }
}

@media (max-width: 991px) {
  .m-sm-left {
    text-align: left;
  }
  .m-sm-center {
    text-align: center;
  }
  .m-sm-right {
    text-align: right;
  }
}

@media (max-width: 767px) {
  .m-xs-left {
    text-align: left;
  }
  .m-xs-center {
    text-align: center;
  }
  .m-xs-right {
    text-align: right;
  }
}

@media (max-width: 575px) {
  .m-xx-left {
    text-align: left;
  }
  .m-xx-center {
    text-align: center;
  }
  .m-xx-right {
    text-align: right;
  }
}

img,
video,
audio,
iframe,
object {
  max-width: 100%;
}

.btn {
  border-radius: 5px;
  background: transparent;
  border: 1px solid #1271db;
  color: #1271db;
  display: inline-block;
  height: 44px;
  outline: none;
  padding: 0 25px;
  text-align: center;
  text-decoration: none;
  text-transform: capitalize;
}

.btn:hover, .btn:focus {
  background: #1271db;
  border-color: #1271db;
  color: #fff;
  text-decoration: none;
}

.btn:active {
  background: #0e59ac;
  border-color: #0e59ac;
}

.btn-primary {
  background-color: #1271db;
  border-color: #1271db;
  color: #fff;
}

.btn-primary:hover, .btn-primary:focus {
  background: #1065c3;
  border-color: #1065c3;
  color: #fff;
}

.btn-primary:active {
  background: #0e59ac;
  border-color: #0e59ac;
  color: #fff;
}

.btn-lg {
  font-size: 1.4em;
  height: 66px;
  padding: 0 60px;
}

.btn-sm {
  font-size: .85em;
  height: 30.8px;
  padding: 0 10px;
}

.btn-link {
  box-shadow: none;
  background: transparent;
  border: 0;
  color: #1271db;
  text-shadow: none;
  text-transform: none;
}

.btn-link:hover, .btn-link:focus {
  background: transparent;
  color: #1271db;
  text-decoration: underline;
}

@media screen and (max-width: 480px) {
  .btn {
    display: block;
    margin-bottom: 10px;
    width: 100%;
  }
}

table {
  width: 100%;
}

table th,
table td {
  padding: 5px;
  text-align: left;
}

table th {
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type=text],
input[type=email],
input[type=phone],
input[type=password],
input[type=number],
input[type=search],
textarea {
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border: 1px solid #ccc;
  height: 44px;
  outline: none;
  padding: 5px 10px;
  width: 300px;
}

input[type=text]:focus,
input[type=email]:focus,
input[type=phone]:focus,
input[type=password]:focus,
input[type=number]:focus,
input[type=search]:focus,
textarea:focus {
  border-color: #1271db;
}

input[type=text].full-width,
input[type=email].full-width,
input[type=phone].full-width,
input[type=password].full-width,
input[type=number].full-width,
input[type=search].full-width,
textarea.full-width {
  width: 100%;
}

textarea {
  height: 132px;
}

form {
  margin-bottom: 40px;
}

form .field {
  margin-bottom: 20px;
}

form .hint {
  color: #aaa;
  margin-top: 5px;
}

form .is-required {
  font-style: italic;
}

form .has-error .error-description {
  color: #ff4136;
  margin-top: 5px;
}

form .has-error input[type=text],
form .has-error input[type=email],
form .has-error input[type=phone],
form .has-error input[type=password],
form .has-error input[type=number],
form .has-error input[type=search],
form .has-error textarea {
  border-color: #ff4136;
}

.alert {
  zoom: 1;
  border-left: 3px solid;
  margin-bottom: 40px;
  padding: 10px;
}

.alert:before, .alert:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden;
}

.alert:after {
  clear: both;
}

.alert.alert-danger {
  background: #ffeae9;
  border-color: #ff4136;
  color: #e90d00;
}

.alert.alert-success {
  background: #e6f9e8;
  border-color: #2ecc40;
  color: #25a233;
}

.alert.alert-warning {
  background: #fff2e7;
  border-color: #ff851b;
  color: #e76b00;
}

.alert.alert-info {
  background: #cfe9ff;
  border-color: #0074d9;
  color: #0059a6;
}

.alert.alert-empty {
  background: #f4f4f4;
  border: 1px dashed #ddd;
  text-align: center;
}

.alert p:last-child {
  margin-bottom: 0;
}

#menu nav ul li a.active, .top-header .user-pref#search:hover {
  background-color: #006eb9;
  color: #ffffff;
}

#menu {
  padding: 0;
}

#menu nav ul {
  list-style: none;
  position: relative;
  padding: 0;
  text-align: right;
  margin-bottom: 0;
}

#menu nav ul li {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;
}

#menu nav ul li a {
  color: #006eb9;
  display: block;
  text-decoration: none;
  padding: 8px 12px;
  border-radius: 20px;
  text-transform: uppercase;
}

#menu nav ul li a.active:hover {
  color: #ffffff;
}

#menu nav ul li a:hover {
  color: #29b34b;
}

#menu nav ul li:hover > ul {
  display: block;
}

#menu nav ul li ul {
  color: white;
  display: none;
  position: absolute;
  top: 100%;
  padding: 0;
  z-index: 99999;
  padding-top: 20px;
  margin: 0;
}

#menu nav ul li ul li {
  float: none;
  width: 200px;
}

#menu nav ul li ul li a {
  text-transform: uppercase;
  border-radius: 0;
  background-color: rgba(0, 110, 185, 0.9);
  color: white;
  padding: 10px;
  text-overflow: ellipsis;
  word-wrap: normal;
  text-align: left;
  word-wrap: break-word;
}

#menu nav ul li ul li:hover > a {
  background-color: #29b34b;
  color: #ffffff;
}

#menu nav ul li ul li ul {
  top: 0;
  left: 100%;
  padding: 0;
}

#menu ul#social-icons {
  padding: 0;
  margin: 0;
  margin-bottom: 30px;
  text-align: right;
}

#menu ul#social-icons li {
  display: inline-block;
  padding: 0 8px;
}

#menu ul#social-icons li a img {
  max-height: 18px;
}

#menu ul#social-icons li #search {
  cursor: pointer;
  color: #006eb9;
  font-size: 14px;
}

#menu ul#social-icons li #search:hover {
  color: #29b34b;
}

@media (max-width: 991px) {
  #menu ul#social-icons {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

* {
  box-sizing: border-box !important;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.clear {
  clear: both;
}

.right {
  float: right;
}

.left {
  float: left;
}

.list-unstyled {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

.valign {
  vertical-align: middle;
}

hr {
  border: 0;
  border-bottom: 1px solid #ddd;
  margin: 40px 0;
}

.subtle {
  color: #aaa;
}

.relative {
  position: relative;
}

.relative {
  position: relative;
}

.no-padding {
  padding: 0;
}

.left-0-padding {
  padding-left: 0;
}

.right-0-padding {
  padding-right: 0;
}

.top-0-padding {
  padding-top: 0;
}

.bottom-0-padding {
  padding-bottom: 0;
}

.dark-blue {
  color: #004161;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.title-lg-inline {
  display: inline-block;
}

@media (max-width: 991px) {
  .title-lg-inline {
    display: block;
  }
}

.title-md-inline {
  display: inline-block;
}

@media (max-width: 768px) {
  .title-md-inline {
    display: block;
  }
}

.center-of-unknown:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.center-of-unknown > * {
  display: inline-block;
  vertical-align: middle;
  max-width: 98%;
}

#header-content {
  position: relative;
}

.top-header {
  background-color: #2C2C2C;
  padding: 0;
  color: #ffffff;
  /*end of USER-PREF*/
}

.top-header .user-pref {
  float: right;
  /*end of currency*/
  /*end of search*/
}

.top-header .user-pref#currency .currency-cont {
  margin: 0;
  padding: 0;
  /*end of li*/
}

.top-header .user-pref#currency .currency-cont li {
  display: inline-block;
  border-left: 1px solid #151515;
  box-shadow: rgba(255, 255, 255, 0.2) -1px 0 0 0;
  padding: 10px;
  /*end of a*/
}

.top-header .user-pref#currency .currency-cont li * {
  display: inline-block;
  vertical-align: middle;
}

.top-header .user-pref#currency .currency-cont li a {
  text-transform: uppercase;
  color: white;
  font-size: 10px;
  text-decoration: none;
  line-height: 1;
  transition: all 0.5s ease-in-out;
}

.top-header .user-pref#currency .currency-cont li .flag {
  vertical-align: middle;
}

.top-header .user-pref#search {
  padding: 0 15px;
  line-height: 36px;
  cursor: pointer;
  border-left: 1px solid #151515;
  border-right: 1px solid #151515;
  box-shadow: rgba(255, 255, 255, 0.2) -1px 0 0 0, rgba(255, 255, 255, 0.2) 1px 0 0 0;
  transition: all 0.5s ease-in-out;
}

.top-header .user-pref#search.active {
  background-color: #369FDC;
}

.bottom-header {
  padding: 0;
  padding-top: 40px;
  padding-bottom: 20px;
}

.bottom-header #logo {
  padding: 0;
  padding-bottom: 10px;
}

@media (max-width: 991px) {
  .bottom-header {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

#menuBtn {
  position: absolute;
  top: 5%;
  right: 5%;
  font-size: 38px;
}

#banner {
  position: relative;
  transition: all 0.5s ease-in-out;
  /*end of slides*/
  /*end of descs*/
}

#banner .slick-slider {
  margin-bottom: 0 !important;
}

#banner .slides {
  position: relative;
}

#banner .slides .slide {
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
}

@media (max-width: 768px) {
  #banner .slides .slide {
    height: 320px;
  }
}

#banner .slides .slide[href="#"] {
  cursor: default;
}

#banner .slides .slick-dots {
  z-index: 9999;
  bottom: 20px;
  width: auto;
  right: 0;
}

#banner .slides .slick-dots li button:before {
  opacity: 1;
  color: #ffffff;
  font-size: 12px;
}

#banner .slides .slick-dots li.slick-active button:before {
  opacity: 1;
  color: #3c9fd8;
  font-size: 12px;
}

#banner .slides .slick-dots li.slick-active button:hover:before {
  opacity: 1;
  color: #3c9fd8;
  font-size: 12px;
}

#banner .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}

#banner .descs {
  width: 100%;
  max-width: 620px;
  position: absolute;
  background-color: transparent;
  padding: 30px;
  bottom: 20px;
  transition: opacity 0.5s ease-in-out;
}

@media (max-width: 768px) {
  #banner .descs {
    max-width: none;
    width: 90%;
  }
}

#banner .descs .desc .title {
  color: #ffffff;
  line-height: 1;
  margin-bottom: 15px;
}

@media (max-width: 575px) {
  #banner .descs .desc .title {
    font-size: 20px !important;
  }
}

#banner .descs .desc p {
  color: #ffffff;
}

@media (max-width: 575px) {
  #banner .descs .desc p {
    font-size: 25px !important;
  }
}

#banner .descs .desc .link {
  color: #ffffff;
  background-color: rgba(41, 179, 75, 0.8);
  text-transform: uppercase;
  padding: 15px 45px;
}

@media (max-width: 575px) {
  #banner .descs .desc .link {
    padding: 15px 25px;
  }
}

#banner .descs .desc .link:before {
  content: '\f05a';
  font-family: 'fontAwesome';
  margin-right: 5px;
}

#banner .descs .desc .link:hover {
  background-color: #29b34b;
}

#banner .descs.slick-slider .slick-dots {
  bottom: 0;
  left: 0;
}

#banner .descs.slick-slider .slick-dots li button {
  width: 10px;
  height: 10px;
}

#banner .descs.slick-slider .slick-dots li button:before {
  font-size: 10px;
  color: #1ca7e0;
  transition: all 0.5s ease;
  opacity: 0.5;
  width: auto;
  height: auto;
}

#banner .descs.slick-slider .slick-dots li.slick-active button:before, #banner .descs.slick-slider .slick-dots li button:hover:before {
  color: #004161;
  opacity: 1;
  font-size: 12px;
}

#banner .descs.slick-slider .slick-prev:before, #banner .descs.slick-slider .slick-next:before {
  color: #004161;
}

#banner .descs.slick-slider .slick-slide:focus {
  outline: none;
}

.breadcrumb-wrapper {
  position: absolute;
  height: 40px;
  background-color: rgba(41, 179, 75, 0.8);
  width: 100%;
  z-index: 1;
  display: none;
}

.breadcrumb-wrapper.non-absolute {
  position: relative;
}

@media (min-width: 768px) {
  .breadcrumb-wrapper {
    display: block;
  }
}

.inner-title-wrapper {
  position: absolute;
  height: 40px;
  width: 100%;
  z-index: 1;
  bottom: 10%;
}

#inner-banner {
  position: relative;
}

#inner-banner .slide {
  width: 100%;
  height: 240px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

#inner-banner.slick-slider {
  margin-bottom: 0 !important;
}

#inner-banner .overlay-fixed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 99%;
  background: rgba(0, 0, 0, 0.3);
}

.overlay-simple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}

#breadcrumb {
  position: absolute;
  z-index: 1;
  text-transform: uppercase;
  display: none;
  line-height: 40px;
}

@media (min-width: 768px) {
  #breadcrumb {
    display: block;
  }
}

#breadcrumb .text {
  color: #ffffff;
}

#breadcrumb .crumb {
  color: #ffffff;
}

#breadcrumb .crumb:after {
  font-family: 'fontAwesome';
  content: '\f054';
  margin: 0 5px;
  font-size: 10px;
}

#breadcrumb .crumb:last-child:after {
  content: '';
}

#inner-title {
  position: absolute;
  z-index: 1;
  color: #ffffff;
}

#content .top-content {
  margin-top: -60px;
}

#content .page-content h1, #content .page-content h2, #content .page-content h3 {
  color: #006eb9;
  padding: 15px 0;
  margin: 15px 0 0;
}

#content .page-content h2 {
  font-size: 24px;
}

#content .page-content p {
  color: #000000;
  font-size: 12px;
}

#content .page-content img {
  max-width: 100%;
}

#content .page-content .read-more {
  color: #004161;
  font-size: 13px;
  position: relative;
}

#content .page-content .read-more:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-color: #1ca7e0;
  top: 50%;
  left: 100%;
  margin-left: 5px;
  margin-top: -3px;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 3px solid #1ca7e0;
  border-left-color: inherit;
}

#content .page-content .left-content {
  background-color: #ffffff;
  padding-top: 45px;
}

#content .page-content .left-content > *:last-child {
  margin-bottom: 30px;
}

#content .page-content .right-content {
  padding-top: 45px;
}

#content .page-content .right-content > *:last-child {
  margin-bottom: 30px;
}

#content .bottom-content {
  margin-top: 30px;
}

#news-mini {
  border-top: 15px solid rgba(255, 255, 255, 0.1);
  border-bottom: 15px solid rgba(0, 0, 0, 0.06);
  padding: 20px;
  background-color: #1ca7e0;
  transition: all 0.5s ease-in-out;
  /*end of news*/
}

#news-mini:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

#news-mini > * {
  display: inline-block;
  vertical-align: middle;
  max-width: 98%;
}

#news-mini .title-cont {
  position: relative;
  border-right: 1px solid #0083B4;
  box-shadow: #16BCF9 1px 0 0 0;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  #news-mini .title-cont {
    width: 100%;
    border: none;
    box-shadow: none;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #0083B4;
    box-shadow: #16BCF9 0 1px 0 0;
    transition: all 0.5s ease-in-out;
  }
}

#news-mini .title-cont .title {
  font-size: 18px;
  color: white;
  margin-bottom: 10px;
}

@media (max-width: 991px) {
  #news-mini .title-cont .title {
    display: inline-block;
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  #news-mini .title-cont .title {
    display: inline-block;
  }
}

#news-mini .title-cont .link {
  color: #004161;
  display: inline-block;
  font-size: 13px;
  position: relative;
}

#news-mini .title-cont .link:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-color: white;
  top: 50%;
  left: 100%;
  margin-left: 5px;
  margin-top: -3px;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 3px solid white;
  border-left-color: inherit;
}

@media (max-width: 768px) {
  #news-mini .title-cont .link {
    float: right;
  }
}

#news-mini .news {
  transition: opacity 0.5s ease-in-out;
  border-right: 1px solid #0083B4;
  box-shadow: #16BCF9 1px 0 0 0;
}

@media (max-width: 991px) {
  #news-mini .news {
    border: none;
    box-shadow: none;
  }
}

#news-mini .news .slide:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

#news-mini .news .slide > * {
  display: inline-block;
  vertical-align: middle;
  max-width: 98%;
}

#news-mini .news .slide .date {
  color: #004161;
  text-align: center;
}

#news-mini .news .slide .date .day {
  font-size: 30px;
}

#news-mini .news .slide .date .month {
  font-size: 14px;
}

#news-mini .news .slide .desc .title {
  color: #004161;
  margin-bottom: 5px;
  text-transform: uppercase;
  display: inline-block;
  max-width: 98%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

#news-mini .news .slide .desc p {
  color: white;
  font-size: 13px;
  margin: 0;
}

#news-mini .news .slide .desc a {
  display: block;
}

#news-mini .news .slide:focus {
  outline: none;
}

#news-mini .slide-arrow:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

#news-mini .slide-arrow > * {
  display: inline-block;
  vertical-align: middle;
  max-width: 98%;
}

#news-mini .slide-arrow .slide-left, #news-mini .slide-arrow .slide-right {
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.5s ease-in-out;
}

#news-mini .slide-arrow .slide-left:hover, #news-mini .slide-arrow .slide-right:hover {
  color: white;
}

#news-mini .slide-arrow .slide-left:hover, #news-mini .slide-arrow .slide-right:hover {
  color: white;
}

#news-mini .slide-arrow .slide-right {
  margin-left: 5px;
}

#newsletter {
  width: 100%;
  cursor: pointer;
  text-align: left;
  border-top: 15px solid rgba(255, 255, 255, 0.1);
  border-bottom: 15px solid rgba(0, 0, 0, 0.06);
  background-color: #005F90;
  padding: 15px;
  transition: all 0.5s ease-in-out;
}

#newsletter:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

#newsletter > * {
  display: inline-block;
  vertical-align: middle;
  max-width: 98%;
}

#newsletter .col {
  padding: 0;
}

#newsletter .col:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

#newsletter .col > * {
  display: inline-block;
  vertical-align: middle;
  max-width: 98%;
}

#newsletter .col img {
  display: inline-block;
  width: auto;
}

#newsletter h3.title {
  color: #1ca7e0 !important;
  font-size: 20px;
  margin: 0 0 5px !important;
  padding: 0;
}

@media (max-width: 991px) {
  #newsletter h3.title {
    font-size: 17px;
  }
}

#newsletter .link {
  color: white;
  font-size: 13px;
  display: none;
  position: relative;
}

#newsletter .link:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-color: #1ca7e0;
  top: 50%;
  left: 100%;
  margin-left: 5px;
  margin-top: -3px;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 3px solid #1ca7e0;
  border-left-color: inherit;
}

#newsletter .form-group {
  display: none;
}

#newsletter .form-group .form-control {
  width: 100%;
  height: 34px;
  font-weight: normal;
}

#newsletter .form-group .form-control-feedback {
  width: auto;
  height: auto;
  margin: 10px;
}

.a-banner {
  margin-top: 30px;
}

#get-quote {
  margin-top: 30px;
}

#event-calendar {
  margin-top: 30px;
}

#home-video-cont {
  padding: 0;
  height: 279px;
}

#home-video-cont a {
  display: block;
  width: 100%;
}

#home-video-cont .vid {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

#home-customer-value {
  border-top: 15px solid rgba(255, 255, 255, 0.1);
  border-bottom: 15px solid rgba(0, 0, 0, 0.06);
  padding: 20px 0;
  background-color: #1ca7e0;
  transition: all 0.5s ease-in-out;
}

#home-customer-value .title {
  color: #004161;
}

#home-customer-value img {
  margin-bottom: 15px;
}

#home-customer-value p {
  color: white;
}

#home-any-last-words {
  margin: 30px 0;
  color: #1ca7e0;
}

#popUpsContainer {
  display: none;
}

.white-popup.popup {
  padding: 10px;
}

footer #footer-content {
  background-color: #2C2C2C;
  border-top: 5px solid #1ca7e0;
  padding: 0 0 40px;
}

footer #footer-content h4 {
  color: #1ca7e0;
  text-transform: uppercase;
  font-size: 13px;
  margin-bottom: 10px;
}

footer #footer-content .bottom-footer {
  border-top: 1px solid #151515;
  box-shadow: #424242 0 -1px 0 0;
  padding-top: 15px;
}

footer #footer-content .bottom-footer .links {
  color: white;
}

footer #footer-content .bottom-footer .site-map {
  color: white;
}

.top-footer {
  background-color: #2C2C2C;
  padding-bottom: 30px;
}

.top-footer h4 {
  color: #1ca7e0;
  text-transform: uppercase;
  font-size: 13px;
  margin-bottom: 10px;
}

.top-footer > * {
  padding-top: 20px;
  /*end of nth-child(2)*/
  /*end of first-child()*/
}

.top-footer > *:nth-child(2), .top-footer > *:nth-child(3) {
  border-right: 1px solid #151515;
  box-shadow: #424242 1px 0 0 0;
}

@media (max-width: 991px) {
  .top-footer > *:nth-child(2) {
    box-shadow: none;
    border: 0;
  }
}

@media (max-width: 768px) {
  .top-footer > *:first-child {
    text-align: left;
  }
}

.top-footer p {
  font-size: 10px;
  color: white;
  margin: 0;
  line-height: 1.4;
}

.top-footer .footer-logo img {
  margin-bottom: 10px;
}

.top-footer .hotline {
  margin-top: 15px;
}

.top-footer .hotline i {
  color: #1ca7e0;
  vertical-align: middle;
}

.top-footer .hotline .phone-number {
  display: inline-block;
  color: white;
  vertical-align: middle;
  margin: 0;
}

.top-footer .hotline .phone-number > a {
  color: white;
}

.top-footer .quick-nav a {
  display: block;
  color: white;
  margin-bottom: 5px;
  font-size: 12px;
}

.top-footer .quick-nav a:after {
  left: 0;
}

.top-footer .quick-nav a:hover {
  text-decoration: underline;
}

.top-footer .quick-nav a:hover:after {
  left: 100%;
}

.top-footer .quick-nav a i {
  color: #1ca7e0;
  padding: 0 5px;
}

.top-footer .address {
  margin-bottom: 10px;
}

#social-icon {
  margin-bottom: 5px;
}

#social-icon ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-block;
}

#social-icon ul li {
  font-size: 16px;
  display: inline-block;
}

#social-icon ul li a {
  color: #F3F3F3;
  padding: 0 5px;
}

#social-icon ul li a img {
  max-height: 18px;
  vertical-align: bottom;
}

/*inner page*/
#inner {
  background-color: white;
}

#inner.no-banner {
  margin-top: 0;
}

#inner #newsletter {
  margin: 0 -15px 30px;
  display: block;
  min-height: 100px;
  width: auto;
}

#navigation-menu {
  position: relative;
}

#searchBar {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
}

#searchBar input {
  position: absolute;
  top: -15px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  text-align: right;
  color: #006eb9;
  border: 0;
  box-shadow: none;
  padding: 0;
}

#searchBar input::-webkit-input-placeholder {
  color: #006eb9 !important;
  font-family: "ProximaNova-Regular" !important;
  font-size: 30px !important;
}

@media (max-width: 991px) {
  #searchBar input::-webkit-input-placeholder {
    font-size: 15px !important;
    text-align: center;
  }
}

#searchBar input::-moz-placeholder {
  color: #006eb9 !important;
  font-family: "ProximaNova-Regular" !important;
  font-size: 30px !important;
}

@media (max-width: 991px) {
  #searchBar input::-moz-placeholder {
    font-size: 15px !important;
    text-align: center;
  }
}

#searchBar input:-moz-placeholder {
  color: #006eb9 !important;
  font-family: "ProximaNova-Regular" !important;
  font-size: 30px !important;
}

@media (max-width: 991px) {
  #searchBar input:-moz-placeholder {
    font-size: 15px !important;
    text-align: center;
  }
}

#searchBar input:-ms-input-placeholder {
  color: #006eb9 !important;
  font-family: "ProximaNova-Regular" !important;
  font-size: 30px !important;
}

@media (max-width: 991px) {
  #searchBar input:-ms-input-placeholder {
    font-size: 15px !important;
    text-align: center;
  }
}

@media (max-width: 991px) {
  #searchBar input {
    font-size: 15px !important;
    text-align: center;
  }
}

.editor-mode [data-block-editable] {
  outline: dashed #7f7f7f 1px;
}

@media (min-width: 768px) {
  #divChangeViews {
    display: none;
  }
}

[data-mh] {
  transition: height 0.3s;
}

#setia-footer-content {
  background-color: #004890;
  position: relative;
}

#setia-footer-content .setia-top-footer {
  padding-top: 45px;
}

#setia-footer-content .setia-top-footer p {
  margin-bottom: 10px;
}

#setia-footer-content .setia-top-footer .logo {
  margin-bottom: 20px;
}

#setia-footer-content .setia-top-footer .company .company-left {
  color: #ffffff;
}

#setia-footer-content .setia-top-footer .company .company-right {
  color: #ffffff;
}

#setia-footer-content .setia-top-footer .weekdays {
  color: #ffffff;
}

#setia-footer-content .setia-top-footer .weekdays .weekdays-left {
  color: #ffffff;
}

#setia-footer-content .setia-top-footer .weekdays .weekdays-right {
  color: #ffffff;
}

#setia-footer-content .setia-top-footer .weekdays:before {
  content: '\f017';
  font-family: 'fontAwesome';
  margin-right: 5px;
}

#setia-footer-content .setia-top-footer .subscribe {
  color: #ffffff;
  cursor: pointer;
}

#setia-footer-content .setia-top-footer .subscribe:before {
  content: '\f1d8';
  font-family: 'fontAwesome';
  margin-right: 5px;
  cursor: pointer;
}

#setia-footer-content .setia-top-footer .subscribe:hover {
  color: #29b34b;
}

#setia-footer-content .setia-top-footer .subscribe:hover a {
  color: #29b34b;
}

#setia-footer-content .setia-top-footer .title .title-left {
  color: #ffffff;
}

#setia-footer-content .setia-top-footer .title .title-right {
  color: #ffffff;
}

#setia-footer-content .setia-top-footer .address {
  color: #ffffff;
  line-height: 20px;
}

#setia-footer-content .setia-top-footer .number {
  color: #ffffff;
  margin-bottom: 8px;
}

#setia-footer-content .setia-top-footer .number .telephone:before {
  content: '\f095';
  font-family: 'fontAwesome';
  margin-right: 5px;
}

#setia-footer-content .setia-top-footer .number .fax:before {
  content: '\f02f';
  font-family: 'fontAwesome';
  margin-right: 5px;
  margin-left: 10px;
}

#setia-footer-content .setia-top-footer .email {
  color: #ffffff;
}

#setia-footer-content .setia-top-footer .email a {
  color: #ffffff;
  cursor: pointer;
}

#setia-footer-content .setia-top-footer .email:before {
  content: '\f0e0';
  font-family: 'fontAwesome';
  margin-right: 5px;
  cursor: pointer;
}

#setia-footer-content .setia-top-footer .email:hover {
  color: #29b34b;
}

#setia-footer-content .setia-top-footer .email:hover a {
  color: #29b34b;
}

@media (max-width: 991px) {
  #setia-footer-content .setia-top-footer .address-block {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

#setia-footer-content .setia-bottom-footer {
  padding: 30px 0;
}

#setia-footer-content .setia-bottom-footer .copyright-left {
  color: #3c9fd8;
}

#setia-footer-content .setia-bottom-footer .copyright-sitemap {
  color: #3c9fd8;
  text-decoration: none;
  display: inline-block;
}

#setia-footer-content .setia-bottom-footer .copyright-sitemap a {
  color: #3c9fd8;
}

#setia-footer-content .setia-bottom-footer .copyright-right {
  color: #3c9fd8;
}

#setia-footer-content #setia-footer-button {
  color: #ffffff;
  background: #3c9fd8;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  font-size: 17px;
  text-align: center;
  line-height: 45px;
  cursor: pointer;
  position: absolute;
  right: calc(50% - 22.5px);
  top: -22.5px;
}

#geospatial-solution {
  padding: 30px 0;
}

#geospatial-solution .gs-text-1 {
  color: #006eb9;
  margin-top: 50px;
}

#geospatial-solution .gs-text-2 {
  color: #006eb9;
  font-size: 30px;
}

#geospatial-solution .gs-text-3 {
  color: #000000;
  line-height: 20px;
}

#geospatial-solution .gs-text-4 {
  color: #000000;
  line-height: 20px;
}

#geospatial-solution .gs-link {
  background: #0066b3;
  border-radius: 20px;
  padding-right: 8px;
  text-transform: uppercase;
  display: inline-block;
}

#geospatial-solution .gs-link a {
  color: #ffffff;
  padding: 12px 20px;
  padding-right: 0px;
}

#geospatial-solution .gs-link:after {
  color: #0066b3;
  content: '\f061';
  font-family: 'fontAwesome';
  margin-left: 10px;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 8px 9px;
}

#geospatial-solution .gs-link:hover {
  background: #29b34b;
}

#geospatial-solution .gs-link:hover:after {
  color: #29b34b;
}

#impact-area {
  padding-top: 230px;
}

#impact-area .ia-text-1 {
  color: #006eb9;
  margin-top: 50px;
}

#impact-area .ia-text-2 {
  color: #006eb9;
  font-size: 30px;
}

#impact-area .ia-text-3 {
  color: #000000;
  line-height: 20px;
}

#impact-area .ia-text-4 {
  color: #000000;
  line-height: 20px;
}

#impact-area .ia-icon-row {
  padding-top: 15px;
}

#impact-area .ia-icon-1, #impact-area .ia-icon-2, #impact-area .ia-icon-3, #impact-area .ia-icon-4 {
  padding-bottom: 10px;
}

#impact-area .ia-icontitle-1, #impact-area .ia-icontitle-2, #impact-area .ia-icontitle-3, #impact-area .ia-icontitle-4 {
  color: #006eb9;
  margin: 0;
  text-transform: uppercase;
}

#impact-area .ia-icontext-1, #impact-area .ia-icontext-2, #impact-area .ia-icontext-3, #impact-area .ia-icontext-4 {
  color: #006eb9;
  margin: 0;
  text-transform: uppercase;
}

#impact-area .ia-icontext-2 {
  padding-bottom: 30px;
}

#impact-area .ia-link {
  background: #0066b3;
  border-radius: 20px;
  padding-right: 8px;
  text-transform: uppercase;
  display: inline-block;
}

#impact-area .ia-link a {
  color: #ffffff;
  padding: 12px 20px;
  padding-right: 0;
}

#impact-area .ia-link:after {
  color: #0066b3;
  content: '\f061';
  font-family: 'fontAwesome';
  margin-left: 10px;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 8px 9px;
}

#impact-area .ia-link:hover {
  background: #29b34b;
}

#impact-area .ia-link:hover:after {
  color: #29b34b;
}

#impact-area .ia-img {
  margin-top: 50px;
}

#our-projects {
  height: 280px;
}

#our-projects.bg-img {
  background-position: bottom !important;
  background-attachment: fixed !important;
}

#our-projects .op-first-row {
  padding-top: 45px;
  padding-bottom: 10px;
}

#our-projects .op-title {
  color: #ffffff;
  font-size: 30px;
}

#our-projects .op-link {
  background: #3c9fd8;
  border-radius: 20px;
  padding-right: 8px;
  text-transform: uppercase;
  display: inline-block;
}

#our-projects .op-link a {
  color: #ffffff;
  padding: 12px 20px;
  padding-right: 0;
}

#our-projects .op-link:after {
  color: #3c9fd8;
  content: '\f061';
  font-family: 'fontAwesome';
  margin-left: 10px;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 8px 9px;
}

#our-projects .op-link:hover {
  background: #29b34b;
}

#our-projects .op-link:hover:after {
  color: #29b34b;
}

#our-projects .op-slick .op-wrap {
  text-align: center;
}

#our-projects .op-slick .op-wrap .op-block {
  width: 240px;
  height: 320px;
  display: inline-block;
  position: relative;
}

#our-projects .op-slick .op-wrap .op-block .op-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: linear-gradient(0deg, black 0%, transparent 50%);
  transition: all 0.5s ease-in-out;
}

#our-projects .op-slick .op-wrap .op-block .op-overlay .op-name {
  color: #ffffff;
  margin: 0;
  padding: 15px;
  position: absolute;
  bottom: 0;
  transition: all 0.5s ease-in-out;
  text-align: left;
}

#our-projects .op-slick .op-wrap .op-block .op-overlay .op-details {
  color: #ffffff;
  text-transform: uppercase;
  margin: 0;
  padding: 15px;
  position: absolute;
  bottom: -30px;
  transition: all 0.5s ease-in-out;
}

#our-projects .op-slick .op-wrap .op-block .op-overlay .op-details:before {
  content: '\f05a';
  font-family: 'fontAwesome';
  margin-right: 5px;
}

#our-projects .op-slick .op-wrap .op-block:hover .op-overlay {
  background: none;
  background-color: rgba(41, 179, 75, 0.8);
}

#our-projects .op-slick .op-wrap .op-block:hover .op-overlay .op-name {
  bottom: 30px;
}

#our-projects .op-slick .op-wrap .op-block:hover .op-overlay .op-details {
  bottom: 7px;
}

#our-projects .op-slick .slick-prev {
  background-color: transparent;
  top: 115%;
  left: 0;
  z-index: 9999;
  width: 40px;
  height: 40px;
  border: 1px solid #006eb9;
  border-radius: 50%;
  text-align: center;
  padding: 9px 0;
  overflow: hidden;
}

@media (max-width: 991px) {
  #our-projects .op-slick .slick-prev {
    left: calc(50% - 50px);
  }
}

#our-projects .op-slick .slick-prev:before {
  font-family: 'fontAwesome';
  content: '\f053';
  font-size: 14px;
  line-height: 1;
  color: #006eb9 !important;
  opacity: 1 !important;
}

#our-projects .op-slick .slick-prev:hover {
  background-color: #29b34b;
  border: 1px solid #29b34b;
}

#our-projects .op-slick .slick-prev:hover:before {
  color: #ffffff !important;
}

#our-projects .op-slick .slick-next {
  background-color: transparent;
  top: 115%;
  left: 55px;
  z-index: 9999;
  width: 40px;
  height: 40px;
  border: 1px solid #006eb9;
  border-radius: 50%;
  text-align: center;
  padding: 9px 0;
  overflow: hidden;
}

@media (max-width: 991px) {
  #our-projects .op-slick .slick-next {
    left: calc(50% + 10px);
  }
}

#our-projects .op-slick .slick-next:before {
  font-family: 'fontAwesome';
  content: '\f054';
  font-size: 14px;
  line-height: 1;
  color: #006eb9 !important;
  opacity: 1 !important;
}

#our-projects .op-slick .slick-next:hover {
  background-color: #29b34b;
  border: 1px solid #29b34b;
}

#our-projects .op-slick .slick-next:hover:before {
  color: #ffffff !important;
}

#our-projects .op-slick .slick-prev:before, #our-projects .op-slick .slick-next:before {
  color: #29b34b;
}

#our-projects .op-slick-arrows .slick-prev {
  background-color: transparent;
  position: relative;
  display: inline-block;
  left: 0;
  z-index: 9999;
  width: 40px;
  height: 40px;
  border: 1px solid #006eb9;
  border-radius: 50%;
  text-align: center;
  padding: 9px 0;
  overflow: hidden;
}

#our-projects .op-slick-arrows .slick-prev:before {
  font-family: 'fontAwesome';
  content: '\f053';
  font-size: 14px;
  line-height: 1;
  color: #006eb9 !important;
  opacity: 1 !important;
}

#our-projects .op-slick-arrows .slick-prev:hover {
  background-color: #29b34b;
  border: 1px solid #29b34b;
}

#our-projects .op-slick-arrows .slick-prev:hover:before {
  color: #ffffff !important;
}

#our-projects .op-slick-arrows .slick-next {
  background-color: transparent;
  right: -15px;
  position: relative;
  display: inline-block;
  z-index: 9999;
  width: 40px;
  height: 40px;
  border: 1px solid #006eb9;
  border-radius: 50%;
  text-align: center;
  padding: 9px 0;
  overflow: hidden;
}

#our-projects .op-slick-arrows .slick-next:before {
  font-family: 'fontAwesome';
  content: '\f054';
  font-size: 14px;
  line-height: 1;
  color: #006eb9 !important;
  opacity: 1 !important;
}

#our-projects .op-slick-arrows .slick-next:hover {
  background-color: #29b34b;
  border: 1px solid #29b34b;
}

#our-projects .op-slick-arrows .slick-next:hover:before {
  color: #ffffff !important;
}

#our-projects .op-slick-arrows .slick-prev:before, #our-projects .op-slick-arrows .slick-next:before {
  color: #29b34b;
}

#our-clients {
  margin-top: 50px;
  height: 330px;
}

#our-clients.bg-img {
  background-position: bottom !important;
}

#our-clients .oc-first-row {
  padding-top: 45px;
  padding-bottom: 10px;
}

#our-clients .oc-title {
  color: #006eb9;
  font-size: 30px;
}

#our-clients .oc-slick.slick-slider {
  padding-left: 0 !important;
}

#our-clients .oc-slick .oc-li .oc-img {
  margin: 0 auto;
}

#our-clients .oc-slick .slick-dots {
  bottom: -30px !important;
}

#our-clients .oc-slick .slick-dots li button:before {
  opacity: 1;
  font-size: 12px;
  color: #3c9fd8;
}

#our-clients .oc-slick .slick-dots li.slick-active button:before {
  opacity: 1;
  color: #0066b3;
  font-size: 12px;
}

#our-clients .oc-slick .slick-dots li.slick-active button:hover:before {
  opacity: 1;
  color: #0066b3;
  font-size: 12px;
}

.collapse-parent-first {
  border: 1px solid #ebebeb;
  color: #000000;
  display: block;
  padding: 15px;
  width: 210px;
  margin: 10px auto;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
}

.collapse-parent-first.in {
  border-color: #29b34b;
  color: #29b34b;
}

.collapse-parent-first:hover {
  border-color: #29b34b;
  color: #29b34b;
}

.collapse-parent {
  color: #000000;
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  line-height: 30px;
  width: 170px;
  margin: 10px auto;
}

.collapse-parent:hover {
  color: #29b34b;
}

.collapse-child {
  width: 180px;
  margin: 10px auto;
}

.collapse-child.in .collapse-parent.in {
  color: #29b34b;
}

.collapse-child.in .collapse-child.in .collapse-parent.in {
  color: #29b34b;
}

#about-us .au-img {
  padding-bottom: 30px;
}

#about-us .au-text-1 {
  color: #006eb9 !important;
  font-family: "ProximaNova-Regular" !important;
  font-size: 24px !important;
}

#about-us .au-text-2 {
  color: #006eb9 !important;
  font-family: "ProximaNova-Bold" !important;
  font-size: 35px !important;
}

#about-us .au-text-3 {
  color: #000000 !important;
  font-family: "ProximaNova-Regular" !important;
  font-size: 14px !important;
  line-height: 20px;
}

#about-us .au-text-4 {
  color: #000000 !important;
  font-family: "ProximaNova-Regular" !important;
  font-size: 14px !important;
  line-height: 20px;
}

#about-us .au-icon-row {
  padding: 45px 0;
}

#about-us .au-icon-row .au-icon-block {
  border: 1px solid #ebebeb;
  padding: 15px;
  margin-bottom: 15px;
}

#about-us .au-icon-row .au-icon-block .au-iconimage {
  line-height: 80px;
}

#about-us .au-icon-row .au-icon-block .au-icontitle-1, #about-us .au-icon-row .au-icon-block .au-icontitle-2, #about-us .au-icon-row .au-icon-block .au-icontitle-3 {
  color: #006eb9;
  margin-bottom: 0;
}

#about-us .au-icon-row .au-icon-block .au-icontext-1, #about-us .au-icon-row .au-icon-block .au-icontext-2, #about-us .au-icon-row .au-icon-block .au-icontext-3 {
  color: #006eb9;
  margin-bottom: 0;
  text-transform: uppercase;
}

#about-us .au-text-5 {
  color: #006eb9 !important;
  font-family: "ProximaNova-Regular" !important;
  font-size: 30px !important;
  position: relative;
  margin-left: 30px;
}

#about-us .au-text-5:before {
  content: '\f10d';
  font-family: 'fontAwesome';
  position: absolute;
  top: 6px;
  left: -30px;
  font-size: 15px;
}

#about-us .au-text-6 {
  color: #000000 !important;
  font-family: "ProximaNova-Regular" !important;
  font-size: 14px !important;
  line-height: 20px;
}

#about-us .au-text-7 {
  color: #006eb9 !important;
  font-family: "ProximaNova-Bold" !important;
  font-size: 35px !important;
}

#about-us .au-text-8 {
  color: #000000 !important;
  font-family: "ProximaNova-Bold" !important;
  font-size: 14px !important;
  line-height: 20px;
}

#about-us .au-text-9 {
  color: #000000 !important;
  font-family: "ProximaNova-Regular" !important;
  font-size: 14px !important;
  line-height: 20px;
}

#all-projects {
  padding: 45px 0;
}

#all-projects .op-wrap {
  display: inline-block;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

#all-projects .op-wrap .op-block {
  width: 240px;
  height: 320px;
  display: inline-block;
  position: relative;
}

#all-projects .op-wrap .op-block .op-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: linear-gradient(0deg, black 0%, transparent 50%);
  transition: all 0.5s ease-in-out;
}

#all-projects .op-wrap .op-block .op-overlay .op-name {
  color: #ffffff;
  margin: 0;
  padding: 15px;
  position: absolute;
  bottom: 0;
  transition: all 0.5s ease-in-out;
  text-align: left;
}

#all-projects .op-wrap .op-block .op-overlay .op-details {
  color: #ffffff;
  text-transform: uppercase;
  margin: 0;
  padding: 15px;
  position: absolute;
  bottom: -30px;
  transition: all 0.5s ease-in-out;
}

#all-projects .op-wrap .op-block .op-overlay .op-details:before {
  content: '\f05a';
  font-family: 'fontAwesome';
  margin-right: 5px;
}

#all-projects .op-wrap .op-block:hover .op-overlay {
  background: none;
  background-color: rgba(41, 179, 75, 0.8);
}

#all-projects .op-wrap .op-block:hover .op-overlay .op-name {
  bottom: 30px;
}

#all-projects .op-wrap .op-block:hover .op-overlay .op-details {
  bottom: 7px;
}

#product-detail {
  padding: 45px 0;
}

#product-detail #pd-panel .panel {
  box-shadow: none;
  border-radius: 0;
  margin-top: 10px;
}

#product-detail #pd-panel .panel .panel-heading {
  background-color: #ffffff;
  border-radius: 0;
  cursor: pointer;
}

#product-detail #pd-panel .panel .panel-heading .panel-title {
  color: #000000;
}

#product-detail #pd-panel .panel .panel-heading .panel-title .indicator {
  border-radius: 50%;
  color: #ffffff;
  font-size: 14px;
  padding: 6px 8px;
  margin: 0;
  cursor: pointer;
}

#product-detail #pd-panel .panel .panel-heading .panel-title .indicator.fa-plus {
  background-color: #006eb9;
}

#product-detail #pd-panel .panel .panel-heading .panel-title .indicator.fa-plus:hover {
  background-color: #29b34b;
}

#product-detail #pd-panel .panel .panel-heading .panel-title .indicator.fa-minus {
  background-color: #29b34b;
}

#product-detail #pd-panel .panel .panel-heading .panel-title .indicator.fa-minus:hover {
  background-color: #006eb9;
}

#product-detail #pd-panel .panel .panel-heading:hover .panel-title {
  color: #29b34b;
}

#product-detail #pd-panel .panel .panel-body {
  color: #000000;
}

#product-detail #pd-panel .panel .panel-body .pd-fact-title {
  color: #000000;
  margin-bottom: 10px;
}

#product-detail #pd-panel .panel .panel-body .pd-fact-text {
  color: #000000;
}

#product-detail .pd-link-1 {
  background: #3c9fd8;
  padding: 12px 15px;
  border-radius: 20px;
  padding-right: 8px;
  text-transform: uppercase;
  color: #ffffff;
  width: 170px;
  display: block;
  margin: 15px 0;
}

#product-detail .pd-link-1:before {
  color: #ffffff;
  content: '\f064';
  font-family: 'fontAwesome';
  margin-right: 10px;
}

#product-detail .pd-link-1:hover {
  background: #29b34b;
}

#product-detail .pd-link-2 {
  background: #006eb9;
  padding: 12px 20px;
  border-radius: 20px;
  padding-right: 8px;
  text-transform: uppercase;
  color: #ffffff;
  width: 170px;
  display: block;
  margin: 15px 0;
}

#product-detail .pd-link-2:before {
  color: #ffffff;
  content: '\f075';
  font-family: 'fontAwesome';
  margin-right: 10px;
}

#product-detail .pd-link-2:hover {
  background: #29b34b;
}

#product-detail #pd-name {
  color: #006eb9;
}

#product-detail #pd-owner-label {
  color: #006eb9;
  display: inline-block;
  padding: 15px 0;
}

#product-detail #pd-owner {
  color: #006eb9;
  text-transform: uppercase;
  display: inline-block;
  padding: 15px 0;
}

#product-detail .zoom_main .zoom_main-block {
  position: relative;
}

#product-detail .zoom_main .zoom_main-block .zoom_main-btn {
  position: absolute;
  bottom: 5%;
  right: 5%;
  color: #ffffff;
  background-color: #006eb9;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 50%;
}

@media (max-width: 991px) {
  #product-detail .zoom_main {
    padding: 0;
  }
}

#product-detail .zoom_gallery {
  padding-right: 120px;
}

@media (max-width: 991px) {
  #product-detail .zoom_gallery {
    display: none;
  }
}

#product-detail .zoom_gallery .zoom_gallery-wrap {
  padding-right: 15px;
}

#product-detail .zoom_gallery .zoom_gallery-wrap .zoom_gallery-block {
  cursor: pointer;
  position: relative;
}

#product-detail .zoom_gallery .zoom_gallery-wrap .zoom_gallery-block .zoom_gallery-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
}

#product-detail .zoom_gallery .zoom_gallery-wrap .zoom_gallery-block .zoom_gallery-overlay .fa {
  font-size: 14px;
  color: #ffffff;
  position: absolute;
  left: calc(50% - 7px);
  bottom: -15px;
  transition: all 0.5s ease-in-out;
}

#product-detail .zoom_gallery .slick-prev {
  top: 50%;
  left: 77%;
  z-index: 9999;
  width: 40px;
  height: 40px;
  border: 1px solid #006eb9;
  background-color: #006eb9;
  border-radius: 50%;
  text-align: center;
  padding: 9px 0;
  overflow: hidden;
}

#product-detail .zoom_gallery .slick-prev:before {
  font-family: 'fontAwesome';
  content: '\f053';
  font-size: 14px;
  line-height: 1;
  color: #ffffff !important;
  opacity: 1 !important;
}

#product-detail .zoom_gallery .slick-prev:hover {
  background-color: #006eb9;
  border: 1px solid #006eb9;
}

#product-detail .zoom_gallery .slick-prev:hover:before {
  color: #ffffff !important;
}

#product-detail .zoom_gallery .slick-next {
  top: 50%;
  right: 2%;
  z-index: 9999;
  width: 40px;
  height: 40px;
  border: 1px solid #29b34b;
  background-color: #29b34b;
  border-radius: 50%;
  text-align: center;
  padding: 9px 0;
  overflow: hidden;
}

#product-detail .zoom_gallery .slick-next:before {
  font-family: 'fontAwesome';
  content: '\f054';
  font-size: 14px;
  line-height: 1;
  color: #ffffff !important;
  opacity: 1 !important;
}

#product-detail .zoom_gallery .slick-next:hover {
  background-color: #29b34b;
  border: 1px solid #29b34b;
}

#product-detail .zoom_gallery .slick-next:hover:before {
  color: #ffffff !important;
}

#product-detail .zoom_gallery .slick-prev:before, #product-detail .zoom_gallery .slick-next:before {
  color: #29b34b;
}

@media (max-width: 991px) {
  .news p {
    display: none;
  }
}

.timeline-heading h4 {
  margin: 0;
}

.timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative;
}

.timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 3px;
  background-color: #eeeeee;
  left: 50%;
  margin-left: -1.5px;
}

.timeline > li {
  margin-bottom: 20px;
  position: relative;
}

.timeline > li:before {
  content: '';
  display: table;
}

.timeline > li:after {
  content: '';
  display: table;
  clear: both;
}

.timeline > li > .timeline-panel {
  width: 46%;
  float: left;
  border: 1px solid #d4d4d4;
  border-radius: 2px;
  padding: 25px;
  position: relative;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
}

.timeline > li > .timeline-panel:before {
  position: absolute;
  top: 26px;
  right: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #ccc;
  border-right: 0 solid #ccc;
  border-bottom: 15px solid transparent;
  content: " ";
}

.timeline > li > .timeline-panel:after {
  position: absolute;
  top: 27px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #fff;
  border-right: 0 solid #fff;
  border-bottom: 14px solid transparent;
  content: " ";
}

.timeline > li > .timeline-badge {
  color: #fff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 16px;
  left: 50%;
  margin-left: -25px;
  background-color: #001489;
  z-index: 100;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}

.timeline > li > .timeline-badge p {
  color: #fff !important;
}

.timeline > li > .timeline-badge.primary {
  background-color: #2e6da4 !important;
}

.timeline > li > .timeline-badge.warning {
  background-color: #3f903f !important;
}

.timeline > li > .timeline-badge.danger {
  background-color: #d9534f !important;
}

.timeline > li > .timeline-badge.info {
  background-color: #5bc0de !important;
}

.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}

.timeline-title {
  margin-top: 0;
  color: inherit;
}

.timeline-body > p,
.timeline-body > ul {
  margin-bottom: 0;
}

.timeline-body > p + p {
  margin-top: 5px;
}

.side-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.side-menu ul li {
  position: relative;
  margin: 10px 0;
  text-transform: uppercase;
}

.side-menu ul li a {
  padding: 15px;
  border: 1px solid #ebebeb;
  display: block;
  text-transform: uppercase;
  padding-right: 30px;
  color: #000000;
  font-weight: bold;
}

.side-menu ul li a:hover {
  border-color: #29b34b;
  color: #29b34b;
}

.side-menu ul li a.arrow {
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  margin: 0;
  padding-right: 15px;
}

.side-menu ul li a.arrow:before {
  content: '';
}

/*# sourceMappingURL=app.css.map */
